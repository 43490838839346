


//Color swatches

.swatches {
	padding-top: 80px;
	padding-bottom: 80px;

	.swatch {
		width: 100px;
		height: 100px;
		background: grey;
	}

	.white-color {
		background: $white;
		border: solid 1px;
		border-color: grey;
	}

	.black-color {
		background: $black;
	}

	.neutral-dk-color {
		background: $neutral-dk;
	}

	.neutral-lt-color {
		background: $neutral-lt;
	}

	.primary-color {
		background: $primary-color;
	}

	.primary-invert-color {
		background: $primary-color-invert;
		border: solid 1px;
		border-color: $primary-color;
	}

	.primary-lighter-color {
		background: $primary-color-lighter;
	}

	.primary-light-color {
		background: $primary-color-light;
	}

	.primary-darker-color {
		background: $primary-color-darker;
	}

	.secondary-color {
		background: $secondary;
	}

	.secondary-invert-color {
		background: $secondary-invert;
		border: solid 1px;
		border-color: $secondary;
	}

	.secondary-lighter-color {
		background: $secondary-lighter;
	}
	
	.secondary-light-color {
		background: $secondary-light;
	}

	.secondary-darker-color {
		background: $secondary-darker;
	}

	.tertiary-color {
		background: $tertiary;
	}

	.tertiary-invert-color {
		background: $tertiary-invert;
		border: solid 1px;
		border-color: $tertiary;
	}

	.tertiary-light-color {
		background: $tertiary-light;
	}

	.tertiary-dark-color {
		background: $tertiary-dark;
	}

}
