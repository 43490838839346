
body {
	overflow-x: hidden;
	color: $neutral-dk;
	width: 100%;
  }

  a[href^=tel] {
	text-decoration:inherit;
	color: inherit;
  }

:-moz-focusring {
	outline: none;
}

pre {
	display: inline-block;
	padding: 2em 4em;
}

code {
	color: inherit;
}

a, .link, %link {
	color: $secondary;
	&:hover {
		color: $secondary-darker;
	}
}

.module-shim {
	position: relative;
	width: 100%;
	height: 2px;
	margin-top: -2px;
	background-color: #777;
	opacity: .2;
	mix-blend-mode: multiply;
}

.two-col-with-photo, .hero-carousel, .hero-carousel-alt, .module-shim {
	& + .module-shim {
		display: none;
	}
}

// Hide Flash Message Until Session Cookie is checked see: _global/nav.js

.flash-message {
	display: none;
}

.base-content {

	> *:last-child {
		margin-bottom: 0;
	}
	
	h1, h2 {
		clear: both;
	}

	p {
		margin-bottom: 1.2rem;
	}

	a {
		text-decoration: underline;
	}

	.blog-social-link a {
		text-decoration: none;
	}

	ul {
		list-style: disc;
		list-style-position: inside;
		margin-bottom: 2em;
	}

	ol {
		list-style: decimal;
		list-style-position: inside;
		margin-bottom: 2em;
	}
	
	li {
		margin-bottom: .5em;
		padding-left: 1em;
	}

	p img {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	p + p img {
		margin-left: 20px;
	}

	img[style*="float"]{

		&[style*="left"]{
			margin-right: 40px;
		}

		&[style*="right"]{
			margin-left: 40px;
		}
	}

	strong {
		color: inherit;
	}

	button + button {
		margin-left: 1.5em;
	}

	iframe {
		max-width: 100%;
	}
}

.generic-content {
	@include mobileSidePadding;
	padding-top: 60px;
	padding-bottom: 30px;

	@include tablet {
		padding-top: 93px;
		padding-bottom: 46px;
	}

	@include desktop {
		padding-top: 112px;
		padding-bottom: 66px;
	}
}

.generic-content-container > *:last-child {
	margin-bottom: 0px;
}

.column-block-container {
	@include mobileSidePadding;

	blockquote.blockquote-block.content-block {
		margin: 0 auto 40px;
	}

}

.content-block {
	
	margin-bottom: 40px;



	@include tablet {
		margin-bottom: 60px;
	}

	> *:first-child {
		margin-top: 0;
	}

	.button {
		margin-top: 1em;
	}
}

.column-block-columns {
	justify-content: center;
}

.image-gallery-block {
	padding-bottom: 40px;

	.image-gallery-content {
		img {
			margin: 0 auto;
		}
	}
}

.crazyclass {
	color: green;
}


@import "./swatches.scss";

