section.error {

	.error-image {
		svg path {
			fill: $primary-color;
		}
	}

	.error-cta {
		@extend %button-outlined-secondary;
	}

}