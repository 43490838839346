.image-gallery-block-container {
	position: relative;

	.image-gallery-carousel {
		padding-bottom: 80px;
		position: relative;

		@include until($tablet){
			padding-bottom: 140px;
		}
	}

	.image-gallery-content {
		width: 100%;
		padding-top: 40%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: 50% 50%;
			@include object-fit( contain, 50% 50% );
		}
	}

	.slick-slider .slick-arrow {
		top: 50%;
		transform: translateY( -50% );

		@media (max-width: 1200px) {
			display: none !important;
		}
	}

	.slick-dots {
		bottom: 0;
	}
} 
