$button-color: $neutral-dk !default;
$button-background-color: $white !default;

$button-border-color: $button-color !default;
$button-border-width: 1px !default;

$button-padding-vertical: calc(0.375em - #{$button-border-width}) !default;
$button-padding-horizontal: 0.75em !default;

$button-hover-color: $secondary !default;
$button-hover-border-color: $button-hover-color !default;

$button-focus-color: $button-hover-color !default;
$button-focus-border-color: $button-hover-color !default;
$button-focus-box-shadow-size: 0 !default;
$button-focus-box-shadow-color: none !default;

$button-active-color: $button-hover-color !default;
$button-active-border-color: $button-hover-color !default;

$button-disabled-background-color: $white !default;
$button-disabled-border-color: $grey-lighter !default;
$button-disabled-shadow: none !default;
$button-disabled-opacity: 0.5 !default;

$button-static-color: $grey !default;
$button-static-background-color: $white-ter !default;
$button-static-border-color: $grey-lighter !default;


.button, %button-extend {
	@extend %control,
	%unselectable;
	background-color: $button-background-color;
	border-color: $button-border-color;
	border-width: $button-border-width;
	color: $button-color;
	cursor: pointer;
	justify-content: center;
	padding-bottom: $button-padding-vertical;
	padding-left: $button-padding-horizontal;
	padding-right: $button-padding-horizontal;
	padding-top: $button-padding-vertical;
	text-align: center;
	white-space: wrap;
	height: auto;

	strong {
		color: inherit
	}

	// @each $name, $pair in $colors {
    // $color: nth($pair, 1);
    // $color-invert: nth($pair, 2);
    // &.is-#{$name} {
    //   background-color: $color;
    //   border-color: transparent;
    //   color: $color-invert;
    //   &:hover,
    //   &.is-hovered {
    //     background-color: darken($color, 2.5%);
    //     border-color: transparent;
	// 	color: $color-invert;
	// }
    //   &:focus,
    //   &.is-focused{
    //     border-color: transparent;
    //     color: $color-invert;
    //     &:not(:active){
	// 	  box-shadow: $button-focus-box-shadow-size rgba($color, 0.25);
	// 	}
	// }
    //   &:active,
    //   &.is-active {
    //     background-color: darken($color, 5%);
    //     border-color: transparent;
	// 	color: $color-invert;
	// }
    //   &[disabled],
    //   fieldset[disabled] & {
    //     background-color: $color;
    //     border-color: transparent;
	// 	box-shadow: none;
	// }
    //   &.is-inverted {
    //     background-color: $color-invert;
	// 	color: $color; 
	// }
    //     &:hover,
    //     &.is-hovered {
    //       background-color: darken($color-invert, 5%);}
    //     &[disabled],
    //     fieldset[disabled] & {
    //       background-color: $color-invert;
    //       border-color: transparent;
    //       box-shadow: none;
	// 	  color: $color;
	// 	}
    //   &.is-loading {
    //     &::after {
	// 	  border-color: transparent transparent $color-invert $color-invert !important;
	// 	}
	// 	}
    //   &.is-outlined{
    //     background-color: transparent;
    //     border-color: $color;
    //     color: $color; 
    //     &:hover,
    //     &.is-hovered,
    //     &:focus,
    //     &.is-focused {
    //       background-color: $color;
    //       border-color: $color;
	// 	  color: $color-invert; 
	// 	}
    //     &.is-loading{
    //       &::after {
	// 		  border-color: transparent transparent $color $color !important;
	// 		}
	// 	}
    //       &:hover,
    //       &.is-hovered,
    //       &:focus,
    //       &.is-focused{
    //         &::after {
	// 			border-color: transparent transparent $color-invert $color-invert !important;
	// 		}
	// 	}
    //     &[disabled],
    //     fieldset[disabled] & {
    //       background-color: transparent;
    //       border-color: $color;
    //       box-shadow: none;
	// 	  color: $color; 
	// 	}
	// 	}
    //   &.is-inverted.is-outlined {
    //     background-color: transparent;
    //     border-color: $color-invert;
    //     color: $color-invert;
    //     &:hover,
    //     &.is-hovered,
    //     &:focus,
    //     &.is-focused {
    //       background-color: $color-invert;
	// 	  color: $color;
	// 	}
    //     &.is-loading
    //       &:hover,
    //       &.is-hovered,
    //       &:focus,
    //       &.is-focused{
    //         &::after {
	// 		  border-color: transparent transparent $color $color !important;}}
	// 		}

    //     &[disabled],
    //     fieldset[disabled] & {
    //       background-color: transparent;
    //       border-color: $color-invert;
    //       box-shadow: none;
	// 	  color: $color-invert;
	// 	}
    //   // If light and dark colors are provided
    //   @if length($pair) >= 4 {
    //     $color-light: nth($pair, 3);
    //     $color-dark: nth($pair, 4);
    //     &.is-light {
    //       background-color: $color-light;
    //       color: $color-dark;}
    //       &:hover,
    //       &.is-hovered {
    //         background-color: darken($color-light, 2.5%);
    //         border-color: transparent;
	// 		color: $color-dark;
	// 	}
    //       &:active,
    //       &.is-active {
    //         background-color: darken($color-light, 5%);
    //         border-color: transparent;
	// 		color: $color-dark;
	// 	}
	// 	}
	// }
	// }

}
