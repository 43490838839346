// Clinic Colors
$primary-color: rgb(27, 27, 255) !default;
$secondary: rgb(0, 126, 0) !default;
$tertiary: rgb(255, 1, 0) !default;

$primary-color-invert: #fff !default;
$secondary-invert: #fff !default;
$tertiary-invert: #fff !default;

// Theme Neutrals
$white: #fff !default;
$black: #000 !default;
$neutral-dk: #5E6B7D !default;
$neutral-lt: #EFF4F9 !default;

$info: hsl(204, 32%, 45%) !default;
$success: hsl(141, 21%, 53%) !default;
$warning: hsl(34, 53%, 52%) !default;
$danger: hsl(0, 57%, 56%) !default;

@import "./_color-functions";

// Bulma Overrides
$text: $neutral-dk !default;
