// nav.js will resize image to the max-height if the logo is larger
.footer {
	.site-logo {
		img {
		width: auto;
		height: auto;
		max-width: 160px;
		max-height: 80px;

		@include tablet {
			max-height: 100px;
		}
	
		}
	}
}

.newsletter-form {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	.newsletter-signup-button {
		height: auto;
	}
	input {
		height: auto;
	}
}

.social-bar {
	display: flex;
	flex-direction: wrap;
	justify-content: space-evenly;
	padding: 15px;
	margin-bottom: 0;

	@include tablet {
		padding: 24px $side-padding;
	}
	
	.social-icons-nested {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;

		@include tablet {
			margin-bottom: 0;
			flex: 1 1 30%;
			justify-content: flex-start;
		}
	}

	.social-icons-item {
		display: inline;
		display: inline-flex;
		font-size: 20px;
		color: $white;

		@include tablet {
			font-size: 24px;
		}
	}

	.newsletter-signup-nested  {
		display: flex;
		flex-direction: column;

		@include tablet {
			flex: 2 1 60%;
			margin-left: 20px;
			max-width: 600px;
		}
	
	}
}

.footer {
	margin: 0 auto;
	background: $white;
	padding: 30px 0 15px;
	width: 90vw;
	display: flex;
	flex-direction: column;
	text-align: center;

	.footer-logo, 
	.footer-copy, 
	.newsletter-signup {
		margin-bottom: 28px;
	}

	@include tablet {
		width: 100%;
		display: block;
		height: auto;
		padding-left: $side-padding;
		padding-right: $side-padding;
		padding-bottom: 30px;
		text-align: left;

		.container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.footer-logo-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include tablet {
			flex: 0 1 33%;
			padding-right: 40px;
			align-items: flex-start;
		}
	}

	.footer-logo {
		max-width: 160px;
		max-height: 100px;
		display: inline-block;
	}

	.footer-copy {
		max-width: 28em;
		margin-left: auto;
		margin-right: auto;
		@include tablet {
			padding-right: 40px;
			margin-left: 0;
			width: 100%;
			max-width: 28em;
		}
	}

	.footer-links {
		display: grid;
		grid-template-columns: [left-start] auto 1fr [right-start] auto;
		margin-bottom: 30px;
		height: auto;
		padding: 0 5vw;	 
		text-transform: capitalize;

		@include tablet {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-content: flex-start;
			margin-bottom: 18px;
			flex: 1 0 42%;
			padding: 0 40px 0 0;
		}
	  }

	.footer-item {
		display: inline;
		display: inline-flex;
		margin: 0 0 25px 0;
		flex: 1 1 12em;

		&:nth-of-type(odd){
			grid-column: left-start;
		}
		&:nth-of-type(even){
			grid-column: right-start;
		}

		@include tablet {
			flex: 0 0 42%;
		}

		@include desktop {
			flex-basis: 32%;
			flex-grow: 0;
		}
	}

	.newsletter-signup {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: auto;

		@include tablet {
			padding-top: 0;
			justify-content: flex-start;
			align-items: flex-start;
			text-align: left;
			flex: 0 0 auto;
			h4, p {
				margin-bottom: 16px;
			}
		}
	   
		.newsletter-signup-title {
			margin-bottom: 18px;
		}
		.newsletter-signup-form input {
			border-style: solid;
			border-width: 3px;
			border-color: $secondary;
			border-radius: 0;
			width: auto!important;
			flex: 1;
		}
		.newsletter-signup-button {
			text-transform: uppercase;
			}   

		h4 {
			text-transform: uppercase;
		}
	}   

	.copyright-info-mobile{
		display: block;
		@include tablet {
			display: none;
		}
	}

	.copyright-info-desktop{
		display: none;
		@include tablet {
			display: block;
		}
	}
	
}

.newsletter-signup-form {
	width: 100%;
}

.copyright-info {
	margin-top: 24px;
}


.pathway-footer-stamp {
	background: lightgrey;
	padding: 26px 20px;
	display:flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-between;
	flex-direction: column;
	img {
		width: 180px;
		height: auto;
	}  
	@include tablet {
		padding: 42px 0;
	}
}

.pathway-footer-stamp-area {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pathway-footer-stamp-text{
	margin-right: 2em;
}

.pathway-footer-stamp-logo {
	width: 178px;
}

.newsletter-signup-nested {
	.newsletter-signup-text {
		display: none;
	}
}

.newsletter-signup-footer {

}

.newsletter-form-message {
	display: none;
	@extend %body-1;
	text-align: center;
	color: $danger;
	margin: .25em auto;
	padding: 10px;
	width: 100%;
}

.newsletter-success {
	background: $white;
	padding: 20px;
	text-align: center;

	p {
		@extend %body-1;
		color: $success;
		margin: 0;
		padding: 0;
	}
}
