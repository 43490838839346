@font-face {
	font-family: 'pathway-icons';
	src:  url('/fonts/pathway-icons.eot?od7dzn');
	src:  url('/fonts/pathway-icons.eot?od7dzn#iefix') format('embedded-opentype'),
	  url('/fonts/pathway-icons.ttf?od7dzn') format('truetype'),
	  url('/fonts/pathway-icons.woff?od7dzn') format('woff'),
	  url('/fonts/pathway-icons.svg?od7dzn#pathway-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  .p-icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'pathway-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

  @mixin make-pIcon {
    font-family: 'pathway-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  .icon-share-google:before {
    content: "\ea88";
  }

  .icon-star-empty:before {
    content: "\e9d7";
  }
  .icon-star-full:before {
    content: "\e9d9";
  }
  .icon-left-quote:before {
    content: "\e901";
  }
  .icon-quote1:before {
    content: "\e901";
  }
  .icon-right-quote:before {
    content: "\e915";
  }
  .icon-quote:before {
    content: "\e915";
  }
  .icon-share-general:before {
    content: "\ea82";
  }
  .icon-share-youtube:before {
    content: "\ea9d";
  }
  .icon-service-diagnostics:before {
    content: "\e923";
  }
  .icon-service-eye-care:before {
    content: "\e924";
  }
  .icon-service-online-pharmacy:before {
    content: "\e925";
  }
  .icon-service-online-store:before {
    content: "\e926";
  }
  .icon-calendar:before {
    content: "\e922";
  }
  .icon-yarn-ball:before {
    content: "\e916";
  }
  .icon-fish-bone:before {
    content: "\e917";
  }
  .icon-dog-bone:before {
    content: "\e918";
  }
  .icon-pet-bowl:before {
    content: "\e919";
  }
  .icon-resource-clipboard:before {
    content: "\e91a";
  }
  .icon-resource-document:before {
    content: "\e91b";
  }
  .icon-heart-paw:before {
    content: "\e91c";
  }
  .icon-resource-link:before {
    content: "\e91d";
  }
  .icon-paw-fill:before {
    content: "\e91e";
  }
  .icon-paw-outline:before {
    content: "\e91f";
  }
  .icon-service-internal-medicine:before {
    content: "\e920";
  }
  .icon-resource-download:before {
    content: "\e921";
  }
  .icon-cloud-upload:before {
    content: "\e80a";
  }
  .icon-cloud-sync:before {
    content: "\e80c";
  }
  .icon-cloud-check:before {
    content: "\e80d";
  }
  .icon-trash:before {
    content: "\e811";
  }
  .icon-cross:before {
    content: "\e870";
  }
  .icon-menu:before {
    content: "\e871";
  }
  .icon-chevron-up:before {
    content: "\e873";
  }
  .icon-chevron-down:before {
    content: "\e874";
  }
  .icon-chevron-left:before {
    content: "\e875";
  }
  .icon-chevron-right:before {
    content: "\e876";
  }
  .icon-arrow-up:before {
    content: "\e877";
  }
  .icon-arrow-down:before {
    content: "\e878";
  }
  .icon-arrow-left:before {
    content: "\e879";
  }
  .icon-arrow-right:before {
    content: "\e87a";
  }
  .icon-service-behavioral-training:before {
    content: "\e903";
  }
  .icon-service-boarding:before {
    content: "\e904";
  }
  .icon-service-cardiology:before {
    content: "\e905";
  }
  .icon-service-dental:before {
    content: "\e906";
  }
  .icon-service-emergency:before {
    content: "\e907";
  }
  .icon-service-grooming-alt:before {
    content: "\e908";
  }
  .icon-service-grooming:before {
    content: "\e909";
  }
  .icon-service-lab-services:before {
    content: "\e90a";
  }
  .icon-service-nutrition:before {
    content: "\e90b";
  }
  .icon-service-parasite:before {
    content: "\e90c";
  }
  .icon-service-pharmacy:before {
    content: "\e90d";
  }
  .icon-service-pharmacy-alt:before {
    content: "\e90e";
  }
  .icon-service-preventive-care:before {
    content: "\e90f";
  }
  .icon-service-radiology:before {
    content: "\e910";
  }
  .icon-service-rehab:before {
    content: "\e914";
  }
  .icon-service-skin-care:before {
    content: "\e911";
  }
  .icon-service-surgery:before {
    content: "\e912";
  }
  .icon-service-wellness-check:before {
    content: "\e913";
  }
  .icon-arrow-left-long:before {
    content: "\e900";
  }
  .icon-arrow-right-long:before {
    content: "\e902";
  }
  .icon-clock-o:before {
    content: "\f017";
  }
  .icon-pencil:before {
    content: "\f040";
  }
  .icon-map-marker:before {
    content: "\f041";
  }
  .icon-share-facebook:before {
    content: "\f082";
  }
  .icon-phone:before {
    content: "\f095";
  }
  .icon-share-twitter:before {
    content: "\f099";
  }
  .icon-share-pinterest:before {
    content: "\f0d2";
  }
  .icon-envelope:before {
    content: "\f0e0";
  }
  .icon-share-linkedin:before {
    content: "\f0e1";
  }
  .icon-angle-left:before {
    content: "\f104";
  }
  .icon-angle-right:before {
    content: "\f105";
  }
  .icon-angle-up:before {
    content: "\f106";
  }
  .icon-angle-down:before {
    content: "\f107";
  }
  .icon-share-instagram:before {
    content: "\f16d";
  }
  .icon-share-yelp:before {
    content: "\f1e9";
  }
  .icon-circle-o:before {
    content: "\f10c";
  }
  .icon-circle:before {
    content: "\f111";
  }
  .icon-caret-left:before {
    content: "\f0d9";
  }
  .icon-caret-right:before {
    content: "\f0da";
  }
  .icon-long-arrow-right:before {
    content: "\f179";
  }
  .icon-comments-o:before {
    content: "\f0e6";
  }
