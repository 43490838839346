.emergency-group {
  padding-top: 46px;

  h2, h3, li, p {
    text-align: center;

    @include tablet {
      text-align: left;
    }
  }

  @include until( $tablet ){
    .column-left {
      display: flex;
      flex-flow: column nowrap;
    }

    .emergency-info {
      order: 1;
    }

    .emergency-services {
      order: 2;
    }

    .emergency-page-image {
      order: 3;
    }
  }

  @include tablet {
    padding-top: 156px;
    padding-bottom: 100px;
  }

  @include widescreen {
    .columns.is-variable.is-8 {
      --columnGap: 4rem;
    }
  }

  .emergency-page-image {
    @extend %brand-border-top;
    img {
        width: 100%;
    }
    @include tablet {
      margin-bottom: 90px;
    }
  }

  .emergency-services,
  .emergency-info {
    @include mobileSidePadding;
    margin-bottom: 65px;

    p, li {
      margin-bottom: 1.5em;
    }
  }

    .emergency-info,
    .emergency-services,
    .emergency-three-col {
    h2 {
      margin-bottom: 36px;

      @include tablet {
        margin-bottom: 50px;
      }
    }
    li, p {
      font-size: 12px;

      @include tablet {
        font-size: 16px;
      }
    }
  }
  
}





.emergency-info {

  @include tablet {
    margin-bottom: 78px;
  }

  .einfo-intro {
    margin-bottom: 15px;
    @include tablet {
      margin-bottom: 30px;
    }

    p {
      line-height: 1.2;
      // font-size: 14px;

      @include tablet {
        // font-size: 16px;
      }
    }
  }

  .einfo-item p{
    margin-bottom: .5em;


    &:before {
      content: "\2010\2003";
    }
  }

}


.emergency-services {

  @include tablet {
    margin-bottom: 0;
  }

  .eservices-list li,
  .link {
    margin-bottom: 1.5em;

    @include tablet {
    }
  }

  .eservices-list {
    .link {
      color: $neutral-dk;
      &::after {
        content: none;
      }
      &:hover {
        color: darken( $neutral-dk, 30%);
      }
    }
  }

}

.emergency-three-col {
  @include mobileSidePadding;
  padding-top: 25px;

  @include tablet {
    padding-top: 0;
  }

  .e3col-intro, .e3col-column {
    margin-bottom: 55px;
  }

  .e3col-intro {
    
    p {
      @include until( $tablet ){
        
      }
    }
  }

  .e3col-content {
    h3 {
      color: $secondary;
      margin-bottom: 1em;
      line-height: 1.1;
    }
    p, li {
      margin-bottom: 1em;
    }
    li {
      list-style: none;
      padding: 0;
      
      &::before {
        content: "\2010\2003";
      }
    }

    @include tablet {
      p {
        margin-bottom: 2em;
      }
    }
  }
  
}