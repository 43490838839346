.main-logo {

	img {
		width: auto;
		height: auto;
		max-width: 160px;
		max-height: 60px;

		@include tablet {
			max-height: 76px;;
		}

		@include desktop {
			max-height: 100px;
		}

	}
}
.navbar {
	z-index: 150;
			//IE Hack for flexbox, Min-height is set to 3.25rem in Bulma
			min-height: 3.125rem;
			height: auto;
}

.navbar-brand {
    z-index: 10;
	@include until( $desktop ){
		align-items: center;
	}
}

.header {
	min-height: 60px;

	@include tablet {
		min-height: 100px;
	}

	@include until( $desktop ){
					
		.navbar-dropdown {
			max-height: 500px;
			overflow-y: scroll;
		}
	}
	
}

@include desktop {

	.navbar-container {
	}

	.navbar-brand {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.navbar-cta {
		min-height: 42px;
	}

	.navbar-menu {
		padding-left: 170px;
		width: 100%;

		.main-nav {
			flex-wrap: wrap;
		}

	}
}

//Menu JS Fallback

@include desktop {

	.main-nav li {
		position: relative;
	}
	.main-nav ul {
		position: absolute;
		display: none; 
		top: 100%;
		left: 0;
		z-index: 99;
	}
	.main-nav > li {
		float: left;
	}
	.main-nav li:hover > ul,
	.main-nav li.sfHover > ul {
		display: block;
	}
	
	.main-nav a {
		display: block;
		position: relative;
	}
	.main-nav ul ul {
		top: 0;
		left: 100%;
	}
}

.has-fixed-header {

	.navbar-brand,
	.navbar-cta,
	.navbar-menu,
	.navbar-item,
	.navbar-burger {
		transition: transform .2s;
	}

}

.fixed-header {

	main {
		padding-top: 100px;

		@include tablet {
			padding-top: 110px;
		}

		@include desktop {
			padding-top: 200px;
		}
	}

	.navbar-container {
		z-index: 510;
		display: flex;
		align-items: center;
	}

	.navbar {
		width: 100%;
	}
	
	.header {
		position: fixed;
		width: 100%;
		z-index: 500;
		padding: 0;
		display: flex;
		justify-content: center;
		background: $white;
		height: 62px;
		min-height: 0;
		// overflow: hidden;
		top: 0;

		.navbar-cta,
		.navbar-container {
			width: 100%;
		}

		@include until( $desktop ){
			.site-logo {
				transform: scale( .8 );
				transform-origin: 100% 50%;
			}

			.navbar-brand {
				margin-bottom: 0;
			}

			.navbar-menu.is-active {
				position: fixed;
				width: 100%;
				background: $white;
				top: 62px;
			}

		}

		@include tablet {
			height: 78px;

			.navbar-menu.is-active {
				top: 78px;
			}
		}
	}

	@include desktop { 

		.header {
			height: 94px;
		}

		.navbar-cta {
			width: 100%;
			left: 0;
		}

		.navbar-cta,
		.navbar-container {
			position: fixed;
			padding: 0;
			padding-top: 4px;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
		}

		.navbar-container {

		}

		.navbar-cta > div {
			transform: scale(.8);
		}

		.navbar-brand,
		.navbar-cta > div,
		.navbar-menu {
			transition: .2s;
		}

		.navbar-menu {
			transform: scale(.8);
		}
		.navbar-brand {
			transform: scale(.7);

		}
	
		.navbar-container {
			top: 30px;
		}
	
	
		.navbar-brand {
			transform-origin: 0 50%;
	
			.main-logo {
				height: 76px;
				padding-bottom: 0;
			}
		}
	
		.navbar-menu {
			transform-origin: 100% 100%;
		}
	
		.navbar-cta > div {
			transform-origin: top right;
		}

		.header .menu-item.navbar-item {
			padding-bottom: 0;
		}
	}
}
