.team-cards {
	text-align: center;

	h2 {
		// font-size: 18px;
		// text-transform: uppercase;
		margin-bottom: 40px;
		@include tablet {
			// font-size: 35px;
			margin-bottom: 130px;
		}
	}

	.team-card {
		p, .team-description {
			max-width: 26em;
			margin-left: auto;
			margin-right: auto;
		@include tablet {
            margin-left: 0;
            margin-right: 0;
			}
		}
		h3, h4, p, .team-description {
			padding: 0 $side-padding;
			@include tablet {
				padding: 0 40px;
			}
		}
		@include tablet {
			margin-bottom: 90px;
		}
		@include desktop {
			margin-bottom: 135px;
		}
	}

	.team-photo {
		width: 160px;
		height: 160px;
		margin: 0 auto 40px auto;
		border-radius: 50%;
		overflow: hidden;
		img{
			min-width: 100%;
			min-height: 100%;
			width: 100%;
		}
	}

	.team-name {
		color: $secondary;
		margin-bottom: 20px;
	}
	
	.team-title {
		margin-bottom: 20px;
	}

}

.leadership-cards {
	text-align: center;
	background: $neutral-lt;
	padding-top: 60px;
	.card-grid-container {
		justify-content: flex-start;
	}
	@include tablet {
		text-align: left;
		padding-top: 100px;
	}
	@include desktop {
		padding-top: 140px;
	}

	h2, h3, h4 {
		text-transform: uppercase;
	}
	
	h2 {
		// font-size: 18px;
		// text-transform: uppercase;
		margin-bottom: 40px;
		text-align: center;
		@include tablet {
			// font-size: 35px;
			margin-bottom: 130px;
		}
	}

	.leadership-card {

		p, .leadership-description {
			max-width: 26em;
			margin-left: auto;
			margin-right: auto;
			@include tablet {
				margin-left: 0;
				margin-right: 0;
			}
		}

		h3, h4, p, 
		.modal-slide-button,
		.leadership-description {
			padding-left: $side-padding;
			padding-right: $side-padding;
			@include tablet {
				padding-left: 40px;
				padding-right: 40px;
			}
			@include desktop {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		@include tablet {
			margin-bottom: 90px;
		}
		@include desktop {
			margin-bottom: 135px;
		}
    }

	.leadership-photo {
		width: 224px;
		height: 224px;
		margin: 0 auto 40px auto;
		overflow: hidden;
		img{
			min-width: 100%;
			min-height: 100%;
			width: 100%;
		}
		@include desktop {
			width: 250px;
			height: 250px;
		}
	}

	.leadership-name {
		color: $secondary;
		margin-bottom: 20px;
	}
	
	.leadership-title {
		margin-bottom: 20px;
	}
} 

.leadership-cards, .team-cards {
	a {
		position: relative;
		z-index: 2;
	}
    .card-intro.base-content {
        @include mobileSidePadding;
        text-align: initial;
        margin-top: -20px;
        margin-bottom: 40px;

        @include tablet {
            margin-top: -80px;
            margin-bottom: 80px;
        }
    }

    .is-empty {
        background: $primary-color-light;
        position: relative;
        svg {
            position: absolute;
            width: 50%;
            margin: 0 auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: .2;
            path {
                fill: $secondary;
            }
        }
    }

	.modal-slide-button {
		display: block;
		padding-top: 20px;
	}

	.modal-slide {
		padding: 50px 30px 20px;
		p {
			max-width: 38em;
			margin: 0 auto 1.5em auto;
		}
	}

	@include desktop {
		.modal-content {
			width: 780px;
		}
	}

	.group-name{
		@include until( $tablet ){
			height: 100%;
			display: flex!important;
			align-items: center;
			justify-content: center;
		}
	}

}

.team-photo, .leadership-photo {
	img {
		object-fit: cover;
		object-position: 50% 0;
		@include object-fit( cover, 50% 0 );
	}
}