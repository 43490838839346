.promo-grid {

	@include tablet {
		padding: 170px 0 260px;
	}
	
	.promo-grid-section-title {
		margin-bottom: 80px;

		@include tablet {
			margin-bottom: 162px;
		}
	}

	.promo-grid-item {
		@include tablet {
			padding-top: 52px;
		}
	}

	.promo-grid-title {
		@extend %body-1;
		text-transform: uppercase;
	}

	.promo-grid-offer{
		@extend %h2;
	}

	.promo-grid-description,
	.promo-grid-description p {
		@extend %body-2;
	}
	
	.slick-arrow {
		display: none !important;
	}
	
}
