.resource-links {

	.resource-links-title {
		margin-bottom: 80px;

		@include tablet {
			margin-bottom: 44px;
		}
	}

	.p-icon {
		display: none;
	}

    .resource-links-description {
        @include until($tablet){
            display: none;
        }
	}

	.resource-links-list li a {
		@extend %link;
	}
	
}