.blog-featured {
	.container {
	  @extend .is-fullhd;
	} 
 
  .blog-featured-image-wrap {
	width: 100%;
	max-height: 50vw;
	overflow: hidden;
	img {
	  width: 100%;
	  max-width: none;
	  height: auto;
	  display: block;

	}
	@media (min-width: $tablet){
	  max-height: none;
	}
  }

  .blog-featured-caption {
	background: $neutral-lt;
	text-align: center;
	padding: 45px 0 1px;
	h2, a {
	  display: block;
	  margin-bottom: 30px;
	}
	@media (min-width: $tablet) {
	  padding-top: 72px;
	  h2, a {
		margin-bottom: 51px;
	  }
	}
  }

}