.event-dropdown-button {
	@extend %button-outlined-secondary;
}

.event-link {
	@extend %link;
	color: $neutral-dk;
}

.events-grid-container {
	@extend .is-widescreen;
}

.events-grid {

	@include tablet {
		padding: 0px 0 165px;
	}

	.event-item-content {
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 30px;
		}
	}

	.event-thumbnail {
		width: 100%;
		height: 306px;
		margin: 0 auto;

		@include tablet {
			flex: 1 1 30%;
			margin: 0;
			border-top: solid 4px;
			border-color: $secondary;
			margin-right: 5%;
		}

		@include fullhd {
			margin-right: 10%;
		}

		img {
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
			width: 100%;
			height: 100%;
		}
	}

	.event-text {
		text-align: center;
		padding: 70px 40px 40px;

		@include tablet {
			text-align: left;
			flex: 0 1 470px;
			padding: 0 40px 0 0;
		}

		@include widescreen {
			flex-basis: 40%;
		}
	}

	.event-title,
	.event-date,
	.event-body {
		margin-bottom: 20px;
	}

	.event-title {
		@extend %h2;
	}

	.event-date {
		letter-spacing: .125em;
	}

	.event-body {
		@extend %body-2;
	}

	.event-link-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include tablet {
			flex-direction: row;
		}
	}

	.event-dropdown {
		margin-bottom: 14px;

		@include tablet {
			margin-bottom: 0;
			padding-right: 2em;
		}
	}

	.event-dropdown-button {
		width: 220px;
	}

	.event-add-calendar {
		border-radius: 0;
		border-color: $neutral-dk;
	}

	.event-dropdown-menu {
		left: 50%;
		min-width: 100%;
		transform: translateX( -50% );
	}

	.event-calendar-li {
		margin-bottom: 20px;

		@include tablet {
			margin-left: 2em;
		}

		&:last-child {
			margin-bottom: 0;
		}

		a {
			text-transform: uppercase;
			color: $neutral-dk;

			&:hover {
				color: $secondary;
			}
		}
	}

	.event-link {
		letter-spacing: .125em;
		@include until( $tablet ){
			&::after {
				content: "";
			}
		}
	}

}
