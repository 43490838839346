.promo-grid {
    background: $primary-color;


      .promo-grid-section-title {
          color: $white;
          text-align: center;
      }

      .promo-grid-item {
          background: $white;
      }

      .promo-grid-offer {
          border: none;
          font-size: 36px;
          @include until($tablet){
            font-size: 29px;
          }
      }

      .promo-grid-title, .promo-grid-offer {
          margin-bottom: 50px;
      }

      .promo-grid-offer {
          color: $secondary;
      }

      .slick-dots li.slick-active button::before {
          color: $white;
          background: $white;
      }


      .slick-dots li button::before {
          color: $white;
          border-color: $white;
      }


}