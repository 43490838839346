.image-gallery, .image-gallery-block {
	
	.slick-slider .slick-arrow::before {
		font-size: 30px;
		color: $primary-color;
	}

	.slick-dots {

		button::before{
			border-color: $primary-color;
		}

		.slick-active button::before {
			background-color: $primary-color;
		}
	}
} 
