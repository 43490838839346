.referring-vets {

    .referring-vets-item {
        padding-top: 45px;
    }

    .referring-vets-title {
        color: $secondary;
        margin-bottom: 30px
    }
    
    .card-grid-link {
        margin-bottom: 0;
        margin-top: auto;
    }

    .slick-arrow {
        display: none;
    }
}