.form_builder {
  &.columns {
    margin-bottom: 10vh;
  }

  .field + .form__divider,
  .field + .form__flexiblegroup_cloneable {
    // margin-top: 2rem;
  }

  label.label {
    font-weight: normal;
  }

  h2 {
    font-size: 1.25rem;
  }
}

// .mod__flashmessage {
//     position: fixed;
//     z-index: 10;
//     top: 0;
//     width: 100%;
//     padding: 0.5rem 0 0.5rem 0;
//     border: 2px solid gold;
//     background-color: rgba(255, 240, 190, 0.96);
//     cursor: pointer;

//     p {
//         margin-bottom: 0;
//     }

//     .closebtn {
//         font-size: 2.5rem;
//         border-radius: 50%;
//     }

//     .container {
//         background: transparent;
//     }

//     &.hasSuccess,
//     &.success {
//         border-color: #00ff00;
//         background-color: rgba(200, 255, 200, 0.95);
//     }

//     &.hasError,
//     &.error {
//         border-color: red;
//         background-color: rgba(255, 200, 200, 0.95);
//     }
// }