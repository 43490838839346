.photo-gallery {
  @include until($tablet) {
    .column {
      padding-top: 0;
      padding-bottom: 8px;
    }
  }
  .photo-gallery-image-thumbnail {
    width: 100%;
    padding-top: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .photo-gallery-image {
    position: absolute;
    background-color: $neutral-lt;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
  }
  .photo-gallery-image-thumbnail:hover .photo-gallery-image {
    transform: scale(1.2);
  }
  .lightbox-modal {
    z-index: 600;
  }
  .photo-gallery-modal-image {
    position: relative;
    img {
      width: 100%;
    }
  }

  .photo-gallery-modal-caption {
    background: $white;
    padding: 20px 40px;
    @include tablet {
      padding: 60px 80px;
      margin-top: 20px;
    }
  }

  //Image Gallery with Text Bock

  &.image-gallery-text {

    .photo-gallery-image-thumbnail:hover {
      .photo-gallery-image {
        transform: none;
        img {
          transform: scale(1.2);
        }
      }
    }

    .photo-gallery-image {
      .column {
        padding-bottom: 0;
      }
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
        @include object-fit( cover, 50% 0 );
        transition: 0.3s;
      }

      .photo-gallery-thumb-caption {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        color: $white;
        padding: 10px 20px;
        @extend %body-1;
        margin: 0;
      }
    }

    .photo-gallery-modal-image {
        img {
            display: block;
        }
      @include tablet {
        // background-color: transparent;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0 0 10px;
        > .columns {
          // background: $white;
          margin-bottom: 0;
        }
      }
    }

    .photo-gallery-modal-caption {
        @extend %body-2;
        border-bottom: solid 1px;
        border-bottom-color: rgb(216, 214, 214);
        padding: 10px; 
        margin: 0 ;
        text-align: left;
        &::after {
            content: none;
        }

        @include tablet {
            border-bottom: none;
           padding-left: 0;
            margin: 0;
        }
      }

    .photo-gallery-modal-body {
      background: $white;
      padding: 40px 20px;
      @include until($tablet) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .modal-content {
      @include tablet {
        width: 80%;
        max-width: 1200px;
      }
    }
    .slick-slide > div {
      display: flex;
      align-content: center;
    }
  } //End image-gallery-text
}
