.social-bar {
	background: $primary-color;
	@include tablet {
		.social-bar-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}
		.newsletter-signup-nested {
			display: flex;
			flex-direction: column;
		}
	}

	.social-icons-nested {
		.social-icons-item {
			color: $primary-color-invert;
			vertical-align: middle;
			margin: 0 10px;

			a {
				color: $primary-color-invert;

				&:hover {
					color: $secondary;
				}
			}
		}

		@include tablet {
			.social-icons-item {
				margin-left: 0; 
				margin-right: 20px;
			}
		}

		@include desktop {
			font-size: 38px;
			.social-icons-item {
				font-size: 38px;
				margin-right: 40px;
			}
		}
	}

	.newsletter-signup-nested {
		flex: 0 1 520px;
		.newsletter-signup-title {
			color: $primary-color-invert;
			@extend %footer-text;
			text-transform: uppercase;
			letter-spacing: .1em;
			text-align: center;
			margin-bottom: 10px;
		}

		@include until( $tablet ){
			display: none;
		}
	}
	.newsletter-signup-input {
		border-radius: 0;
		border: solid 2px;
		border-color: $secondary;
		padding-left: 20px;
	}

	.newsletter-signup-button {
		@extend %footer-text;
		padding: 13px 40px;
		letter-spacing: .1em;
		@include desktop {
			padding: 13px 60px;
		}
	}

}

.footer {
	@extend %footer-text;

	.footer-container {
		width: 100%;
	}

	.footer-logo{
		margin-bottom: 20px;
		@include tablet {
			margin-bottom: 15px;
		}
	}

	.footer-copy {
		margin: 0 auto 18px auto;
		@include tablet {
			margin-left: 0;
		}
		p {
			@extend %footer-text;

		}
	}

	.footer-links {
		@include until( $tablet ){
			max-width: 420px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.footer-item {
		text-align: left;
		margin-bottom: 20px;
		display: inline-block;

		@include tablet {
			flex-basis: 32%;
		}
	}

	.footer-link {
		color: $neutral-dk;
	}


	.newsletter-signup {
		@extend %footer-text;

		.newsletter-signup-text {
			@extend %footer-text;
			padding: 0;
			margin-bottom: 20px;
		}
		h4.newsletter-signup-title {
			font-weight: 700;
			margin-bottom: 16px;
		}
		@include tablet {
			display: none;
		}
	}

	.newsletter-signup-form {
		min-height: 47px;

	}

	.newsletter-signup-button {
		color: transparent;
		font-size: 0px;

		&::after {
			color: $secondary-invert;
			font-size: 16px;
			font-family: "pathway-icons";
			content: "\e902";
		}
	}

} 

.newsletter-signup-button {
	@extend %button-solid-secondary;
}

.pathway-footer-stamp {
	background: $neutral-lt;
}

.pathway-footer-stamp-text{
	@extend %footer-text;
}

.copyright-clinic {
	@extend %footer-text;  
}

.social-bar, .footer {
	.newsletter-signup-input {
		padding-left: 2em;
		&::placeholder {
			text-transform: uppercase;
			opacity: .75;
			color: $black; 
			font-size: 14px;
		}
	}
}