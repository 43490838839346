.contact-map {
	min-height: 590px;
	text-align: center;
	position: relative;
	
	&.is-gapless {
	  @media (min-width: 1600px){
		margin-left: auto;
		margin-right: auto; 
	  }
	}

	.contact-map-container {
	  @include maximize-width;
	}
  
	.contact-map-details-column {
	  @include tablet {
		display: flex;
		justify-content: center;
	  }
	}

  .contact-map-details {
	// @extend %brand-border-top;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	@include desktop {
		// flex: 0;
	}
  }

  .contact-map-details-group {
	padding-left: $side-padding;
	padding-right: $side-padding;
	width: 100%;
		& > div {
			width: 100%;
		}
  }


  .contact-map-details-location-copy span {
	  display: inline-block;
	}
	
	.contact-map-details-hours-copy {
		white-space: pre-line;
	}

  @include tablet {
	  .contact-map-details-group {
		  text-align: left;
	  }
  }


  @include desktop {

	  .contact-map-details-group {
		align-self: flex-start;
		// padding-left: 0;
		// padding-right: 0;
		> div {
		  padding-left: 4em;
		  padding-right: 3em;
		  &::before {
			font-family: 'pathway-icons';
			font-weight: 800;
			position: absolute;
			top: 0;
			left: 1.25em;
			color: $secondary;
		  }
		  &.contact-map-details-phone-emergency{
			padding-right: 4em;
			&::before {
			  top: .56em;
			  color: $white;
			}
		  }
		  &.contact-map-details-phone-emergency, &.contact-map-details-phone-general {
			&::before{
			  font-family: 'pathway-icons';
			  content: '\f095';
			}
		  }
		  &.contact-map-details-email {
			&::before {
			  font-family: 'pathway-icons';
			  content: '\f0e0';
			}
		  }
		  &.contact-map-details-location {
			&::before {
			  font-family: 'pathway-icons';
			  content: '\f041';
			}
		  }
		  &.contact-map-details-hours {
			&::before {
			  font-family: 'pathway-icons';
			  content: '\f017';
			  font-weight: 500;
			}
		  }
		}
	  }
  }

  @media(min-width: 1180px){
	.contact-map-details-group {
	  align-self: center; 
	}
  }

  .column.contact-map-map {
	@include until($desktop){
	  margin-top: 30px;
	}
  }

  #g-map {
	width: 100%;
	height: 300px;
	@include tablet {
	height: 100%;
	  min-height: 450px;
	}
	@include desktop {
	  min-height: 590px;
	}
  }   

  .contact-map-map iframe {
	width: 100%;
	height: 100%;
  }
 
  #g-map {
      overflow: hidden;
      position: relative;
      display: block;
  }
  iframe.embededmap {
      position: relative;
      top: -50px;
      height: calc( 100% + 50px );
  }

/// Multisite Variant ////
  &.multisite {

    #g-map {
        @include tablet-only {
            height: 450px;
        }
    }

    .mutisite-map-contact-area {
				max-width: 760px;
				width: 100%;
    }

    .contact-map-details {
       padding-left: 10px;
       padding-right: 10px;
       max-width: none;

       @media ( min-width: 420px ){
           padding-left: 20px;
           padding-right: 20px;
       }

       @include desktop {
           padding-left: 40px;
           padding-right: 40px;
       }

       p {
           font-size: 12px;

           @include tablet {
               font-size: 15px;
           }
       }
    }

    .contact-map-details-group {
        padding: 0;
    }

    .contact-map-details-email a {
        word-break: break-word;
    }


    .contact-map-details-location,
    .contact-map-details-phone-emergency,
    .contact-map-details-phone-general {
        margin-bottom: 0;
    }

    .contact-map-details-email,
    .contact-map-details-hours {
        margin-bottom: 1.5em;
    }



    @include desktop {
        .contact-map-details-group > div {
            padding: 0;
        }
    }

    .multisite-location-social-list {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;

        li {
						margin-right: 10px;
						font-size: 1.5rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
  }
 
}

