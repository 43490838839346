#landingpage {

  .generic-content {
    position: relative;
    padding-bottom: 60px;
    margin-bottom: 60px;

    &::after {
      content: "";
      display: block;
      width: 90%;
      margin: 0 auto;
      height: 2px;
      background: #EFF4F9;
      max-width: 1200px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include tablet {
      padding-bottom: 120px;
      margin-bottom: 120px;
    }

  } 

  .form-intro-container {
    margin-bottom: 60px;
    @include tablet {
      margin-bottom: 90px;
    }
  }

  .form_builder {
    margin-bottom: 40px;
  }

  .services-list-title {
    margin-bottom: .5em;
  }

  .form-landingpage {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: relative;
      width: 90%;
      margin: 0 auto;
      height: 2px;
      background: $neutral-lt;
      max-width: 1200px;
    }
  }

  .module-forms {
    .form__submitbtn {
      @include tablet {
        margin-top: 0;
        padding-bottom: 20px;
      }
    }
  }

  .services-list {
    padding-top: 70px;

    @include tablet {
      padding-top: 90px;
    }
    @include desktop {
      padding-top: 110px;
    }
  }

}
