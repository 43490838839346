﻿.three-col-img-columns {
	@extend .is-8;
	justify-content: space-around;
}

.three-col-with-image {
	padding: 32px 0;

	@include tablet {
		padding: 134px 32px 154px;
	}

	@media ( max-width: 400px ) {
		margin-left: 5%;
		margin-right: 5%;
	}

	.three-col-img-title, .three-col-img-intro {
		text-align: center;
		@include mobileSidePadding;
	}

	.three-col-img-title {
		margin-bottom: 30px;

		@include tablet {
			margin-bottom: 44px;
		}
	}

	.three-col-img-intro {
		margin-bottom: 40px;
		max-width: 42em;
		margin-left: auto;
		margin-right: auto;

		@include tablet {
			margin-bottom: 120px;
		}
	}

	.three-col-img-column {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 40px;
		justify-content: center;

		@include tablet {
			flex-direction: column;
			margin-bottom: 0;
			max-width: 322px;
		}
	}

	.three-col-img-image {
		flex: 0 0 100px;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin-right: 20px;
		overflow: hidden;

		@include tablet {
			width: 130px;
			height: 130px;
			flex: none;
			max-width: none;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 106px;
		}

		@include desktop {
			width: 170px;
			height: 170px;
		}

		img {
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
			height: 100%;
			display: block;
		}

		@media ( max-width: 400px ){
			display: none;
		}
	}

	.three-col-img-content {
		flex: 2 0 120px;
		max-width: 28em;

		@include tablet {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
		}
	}

	.three-col-img-body,
	.three-col-img-body p {
		@extend %body-2;
		letter-spacing: .125em;
	}

	.three-col-img-body {
		margin-bottom: 16px;
		
		@include tablet {
			margin-bottom: 25px;
		}
	}

	.three-col-img-subtitle {
		@extend %body-1;
		text-transform: uppercase;
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 25px;
		}
	}

	.three-col-img-cta {
		@extend %link;

		@include tablet {
			margin-top: auto;
		}
	}

}
