.services-list {
	padding: 50px 5%;
	text-align: center;

	.columns.services-list-card-grid-container.is-variable, .services-accordion-cols.columns.is-multiline.is-variable {
		position: relative;
		@extend .is-8-desktop;

		@include widescreen {
			--columnGap: 6rem;
		}
	}

	.services-list-column {
		@extend .is-6;
	}

	.services-list-title, 
	.services-list-description {
		margin-bottom: 52px;
	}

	.services-list-description {
		max-width: 48em;
		margin-left: auto;
		margin-right: auto;
	}

	.services-list-card {
		text-align: left;
		position: relative;
		max-width: 430px;
		margin-left: auto;
		margin-right: auto;
		&::before {
			font-family: 'pathway-icons';
			@include until($tablet) {
				content: none;
			}
		}
	}

	.services-list-icon {
		position: absolute;
		z-index: 10;
		color: $primary-color-invert;
		font-size: 32px;
		top: 15px;
		left: 22px;
		line-height: 1;
	}

	.services-list-card-title {
		@extend %body-1;
		text-transform: uppercase;
		background: $primary-color;
		color: $primary-color-invert;
		padding: 20px 20px;
		line-height: 1;
		position: relative;

		&::after {
			font-family: 'pathway-icons';
			display: inline-block;
			content: "\e874";
			position: absolute;
			right: 20px;
			top: 24px;
			@include tablet {
				content: none;
			}
		}

		@include until($tablet){
			padding: 24px 20px 24px 5em;
			margin-bottom: 0;
			&:hover {
				background: $secondary;
				transition: .3s;
				color: $secondary-invert;
				cursor: pointer;
				&::after, &::before {
					color: $secondary-invert;
				}
			}
		}
	}

	.services-list-content {
		background-color: $neutral-lt;
		padding: 40px 20px;
	}

	.services-list-card-body {
		@extend %body-2;
		margin-bottom: 20px;
	}

	@include tablet {
		padding-top: 110px; 
		padding-bottom: 70px; 

		.services-list-title, 
		.services-list-description {
			margin-bottom: 90px;
		}

		.services-list-card {
			max-width: none;
			position: relative;
			padding-bottom: 70px;
			height: 100%;
			display: flex;
			flex-direction: column;

			.services-list-icon {
				color: $secondary;
				font-size: 60px;
				line-height: 1;
				margin:0;
				padding: 0;
				position: absolute; 
				left: 0;
				top: 30px;
			}

			.services-list-card-title, .services-list-content {
				background-color: transparent;
				padding: 0 0 0 90px;
				color: $neutral-dk;
			}

			.services-list-content {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}

		.services-list-card-title {
			margin-bottom: 34px;
		}

		.services-list-card-cta {
			margin-top: auto;
		}

		.services-list-card-grid-container {
			justify-content: flex-start;
		}
	}

	@include desktop {
		.services-list-title {
			// margin-bottom: 130px;
		}

		.services-list-card { 
			.services-list-icon {
				font-size: 80px;
			}

			.services-list-card-title, .services-list-content {
				padding-left: 110px;
			}
		}
	}

}

.services-list-cta {
	@extend %button-outlined-secondary;
	margin-left: auto;
	margin-right: auto;
}


.services-accordion-button {
	@extend %button-outlined-secondary;
}