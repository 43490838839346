$family-primary: "Poppins", sans-serif;

body, h1, h2, h3, h4, h5, h6, p, input {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-weight: 400;
}

a {
	font-family: "Poppins", sans-serif;
  font-weight: normal;
	font-weight: inherit;
}

.hero,
%hero,
h1,
.h1,
%h1,
h2,
.h2,
%h2,
.button-text,
%button-text {
  text-transform: uppercase;
}

h1, 
.h1,
%h1 {
  font-size: 52px;
  letter-spacing: .2em;
  line-height: 1.6;
}

.hero,
%hero {
  font-size: 91px;
  letter-spacing: .1em;
  line-height: 1;
}

.hero-2, 
%hero-2,
.page-title,
%page-title {
  font-size: 24px;
  letter-spacing: .1em;
  @include tablet {
    font-size: 71px;
  }
}

.page-title,
%page-title {
  text-align: center;
}

h2, 
.h2,
%h2 {
  font-size: 20px;
  letter-spacing: .29em;
  line-height: 1.4;
  margin-bottom: .29em;

  @include tablet {
  font-size: 35px;
  }
}

h3 {
	@extend %body-1;
	text-transform: uppercase;
	margin-bottom: .5em;
}

p, 
.body-1,
%body-1 {
  font-size: 16px;
  letter-spacing: .125em;
  line-height: 1.5;
  margin-bottom: .63em;
}

.body-2,
%body-2 {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: .7em;
}

.button-text, 
%button-text {
  font-size: 16px;
  letter-spacing: .125em;
  line-height: normal;
}

%button-extend, .button {
	@extend %button-text;
	padding: 10px 20px;
	border-radius: 1px;
}

%button-outlined-secondary,
.button-secondary,
a.button-secondary {
	@extend %button-extend;
	color: $secondary;
	border-color: $secondary;
	background: transparent;

	a {
		color: $secondary;
		text-decoration: none !important;

	}

	&:hover {
		background: $secondary;
		color: $secondary-invert;

		a {
			color: $secondary-invert
		}
	}


}

%button-solid-secondary, .button-solid-secondary {
	@extend %button-extend;
	color: $secondary-invert;
	background: $secondary;
	border-color: $secondary;

	&:hover {
		background: $secondary-darker;
		border-color: $secondary-darker;
		color: $secondary-invert;
	}
}

%button-outlined-primary, .button-outlined-primary {
	@extend %button-extend;
	color: $primary-color;
	border-color: $primary-color;
	background: transparent;

	&:hover {
		background: $primary-color;
		color: $primary-color-invert;
	}
}

%button-primary-invert, .button-primary-invert {
	@extend %button-extend;
	background: $primary-color;
	color: $primary-color-invert;
	border-color: $primary-color-invert;

	&:hover {
		background: $primary-color-invert;
		color: $primary-color;
		border-color: $primary-color-invert;
	}
}

.button-primary,
a.button-primary {
	@extend %button-primary-invert;
	border-color: $primary-color;

	a {
		color: $primary-color-invert;
		text-decoration: none !important;
	}

	&:hover {
		border-color: $primary-color;
		
		a {
			color: $primary-color
		}
	}


}

%button-white-outline {
	@extend %button-extend;
	background: transparent;
	border-color: $white;
	color: $white;

	&:hover {
		background: $white;
		color: $black;
	}
}

.link, 
%link {
    text-transform: uppercase;
    text-decoration: underline;
    border: none;
    &::after {
      content: "\e902";
      font-family: 'pathway-icons';
      font-size: 75%;
      padding-left: .5em;
      text-decoration: none;
      display: inline-block;
    }
    &:hover {
        color: $secondary-darker; 
    }
	}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.link,
		.three-col-img-cta,
		.resource-links-li a {
			text-decoration: none!important;
		}
}

.footer-text,
%footer-text {
  font-size: 12px; 
  line-height: 1.7;
}

.module-shim {
	background-color: $primary-color-light;
	opacity: .5;

	& + .one-col-promo {
		position: relative;
		margin-top: -2px;
		z-index: 10;
	}
}

.base-content {

	h1 {
		margin-bottom: 1rem;
	}

	h2, h3 {
		margin-top: .75rem;
	}

	h2, h3, h4 {
		margin-bottom: .75rem;
	}

	h3 {
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;

		@include tablet {
			font-size: 22px;
		}
	}

	h4 {
		@extend %body-1;
		text-transform: uppercase;
		font-weight: 400;
	}

	ol, ul {
		margin-bottom: 2.5rem;
	}

	p {
		margin-bottom: 2rem;
	}

	blockquote {
		width: 70%;
		margin: 80px auto;
		border-left: 1px solid;
		border-left-color: $neutral-dk;
		padding-left: 2em;
	}

}

.generic-content {
	padding-top: 0;
	
	@include tablet {
		padding-top: 20px;
	}
}

.page-header-background + .generic-content {
	padding-top: 20px;

	@include tablet {
		padding-top: 60px;
	}
}
