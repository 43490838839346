.blog-article {
	@extend .is-8;
}

.blog-info {
	@extend .is-12-tablet,
	 .is-4-desktop;
}

.blog-content {
	@extend .is-12-tablet,
	.is-8-desktop;
}


.module-blog {
	padding-bottom: 80px;

	.blog-hero {
		img {
			border-bottom-style: solid;
			border-bottom-width: 12px;
			border-bottom-color: $neutral-dk;
			margin-bottom: 28px;
		}
	}

	&.blog-no-hero {
		padding-top: 60px;

		@include tablet {
			padding-top: 80px;
		}
	}

	.blog-info {
		 text-align: center;

		 @include desktop {
			 text-align: left;
		 }
	}

	.blog-content {
		text-align: center;

		@include desktop {
			text-align: left;
		}
	}

	.blog-article {

		h1.blog-title {
			@include desktop-only {
				font-size: 28px!important;
			}
		}

	}


	.blog-social {
		@include until($desktop){
			justify-content: center;
		}
	}

	.base-content {
		blockquote { 
			width: 100%;
			padding: 10px 0 10px 30px;
			margin: 0 0 40px 0;
		}
	}
}

.blog-featured {

	.blog-featured-image-wrap {
		max-height: 720px;
	}

	.blog-featured-title {
		max-width: 48em;
		padding-left: $side-padding;
		padding-right: $side-padding;
		margin: auto;

		a {
			color: $neutral-dk;
		}
	}

	.blog-featured-date {
		display: none;
	}

}

.module-blog-grid {
	@include mobileSidePadding;

	.blog-grid-title {
		text-align: center;
		margin-bottom: 2em;
	}

	.blog-grid-block-link {
		display: flex;
		height: 100%;
	}

}
