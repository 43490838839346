.header {
	padding-top: 24px;

	@include desktop {
		padding-top: 60px;
		padding-bottom: 56px;
	}

	.navbar {
		@include desktop {
			align-items: flex-end;
		}
	}

	.navbar-cta {
		@extend .container;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 100;
		position: relative;

		@include until( $desktop ){
			display: none;
		}
	}

	.main-logo {
		padding: 0;
	}


	.navbar-brand {
		@include mobileSidePadding;
		@include until( $desktop ){
			margin-bottom: 20px;
		}
	}

	.navbar-container .navbar .navbar-menu {
		align-items: center;
		box-shadow: none;
		background: $primary-color;
		padding-top: 30px;

		@include desktop {
			background: $white;
			padding-top: 0;
		}
	}

	.menu-item {
		@include until( $desktop ){
			display: block;
			margin: 0 auto 15px;

			& .menu-link {
				display: flex;
				justify-content: space-between;
				align-items: center;

				& .p-icon {
					flex: 0 1 50%;
					text-align: right;
					height: auto;
					padding: 0;
					display: inline-block;
					position: relative;
					top: -2px;
				}
			}
		}

		&.navbar-item {
			padding: 0;
		}

		@include desktop {
			// margin-right: 14px;

			& .p-icon {
				position: relative;
				top: -2px;
				padding-left: 8px;

			}
		}
	}

	.menu-item.has-dropdown {
		.p-icon::before {
			font-size: 80%;
			display: inline-block;
			transform: rotate( -90deg );
			transition: transform .3s;
		}

		&.sfHover {
			.p-icon::before {
				transform: none;
			}
		}
	}
	
	.menu-link,
	.dropdown-link {
		@include mobileSidePadding;
		color: $black;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		@include desktop {
			max-width: none;
		}
	}

	.dropdown-link {
		// font-weight: 400;

		&:hover {
			color: $secondary;
		}
	}

	.menu-link {
		@extend %button-text;
		padding-top: 16px;
		color: $neutral-dk;

		@include until( $desktop ){
			border: none;
			height: 40px;
			color: $primary-color-invert;

			&:hover {
				background: none;
			}
		}

		@include desktop {
			// font-size: 16px;
			padding: 12px 10px;

			&:hover {
				background: $neutral-lt;
				color: $neutral-dk;
			}
		}
	}

	.menu-link, a.pharma-link, .requestAppt-cta {
		@include desktop-only {
			letter-spacing: initial;
		}
	}

	.menu-link:hover, .menu-item.sfHover .menu-link:hover {
		@include until( $desktop ){
			color: $secondary;
		}
	}

	.menu-item.sfHover .menu-link,
	.menu-item.sfHover {
			color: $neutral-dk;
			background: $neutral-lt;
			z-index: 10;
			position: relative;
	}

	.navbar-link.menu-link.is-current,
	.dropdown-item.is-current .dropdown-link {
		position: relative;
		color: $secondary;
	}

	.navbar-dropdown {
		@extend %body-2;
		background: $neutral-lt;
		color: $neutral-dk;
		box-shadow: none;
		border-radius: 0;
		border: none;
	}

	.dropdown-item {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 0px;
		padding-right: 0;
	}

	.dropdown-link {
		color: $neutral-dk;
		display: block;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		// font-size: 14px;

		@include desktop {
			padding-left: 18px;
			padding-right: 26px;
		}
	}

	.online-pharmacy-mobile {
		@include desktop {
			display: none;
		}
	}

	.appointment-cta-1 {
		display: none;
	}

	.appointment-cta {
		text-align: center;
	}

	.requestAppt-cta {
		@extend %button-primary-invert;
		margin: 44px auto;

		@include desktop {

			background: $white;
			border-color: $secondary;
			color: $secondary;
			margin: 0 0 0 10px;

			&:hover {
				color: $secondary-invert;
				background: $secondary;
				border-color: $secondary;
			}
		}
	}

	.online-pharmacy-desktop a {
		@extend %button-text;
		display: block;
		margin: 0 -12px 20px 0;
		margin-right: -12px;
		position: relative;
		color: $primary-color;
		letter-spacing: 1;

		&::after {
			font-family: "pathway-icons";
			content: "\e876";
			font-size: 80%;
			display: inline-block;
			padding-left: .5em;
		}

		&:hover {
			color: $secondary;
		}

	}

	.online-pharmacy-mobile  {
		text-align: center;
		margin-bottom: 50px;
		
		a {
			@extend %button-text;
			// font-size: 16px;
			color: $white;

			&:hover {
				color: $secondary;
			}
		}
	}

}

.fixed-header {
	@include desktop {
		.header .navbar-cta {
			transform-origin: 100% 0;
			top: -10px;
			margin: 0;
		}

		.requestAppt-cta {
			padding: 4px 16px;
		}

	}
}
