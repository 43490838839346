.input:focus,
.textarea:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.select select.is-focused,
.input:active,
.textarea:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.select select.is-active {
  border-color: $secondary;
}

.input::placeholder, .textarea::placeholder, .select select::placeholder { 
  color: $grey;
  opacity: 1;
}

.module-forms {
  .style-checkbox {
    min-width: 20px;
  }
  
  .form_builder {
    justify-content: center;
  }

  .itemcounter {
    margin: 0;
    padding: 0;
  }

  abbr {
    text-decoration: none;
  }

  form {
    @include mobileSidePadding;
    .columns {
      margin-bottom: 60px;
      @media (min-width: $desktop) {
        margin-bottom: 0;
      }
    }
  }

  .toclone.columns {
    @extend .is-variable;
  }

  .columns {
    @media (min-width: $tablet) {
      --columnGap: 20px !important;
    }
  }

  form .field {
    margin-bottom: 40px;
    position: relative;
    @media (min-width: $tablet) {
      margin-bottom: 10px;
      padding-bottom: 3.5em;
      .help {
        position: absolute;
        bottom: 1.75em;
        height: 1.75em;
        margin: 0;
        padding: 0;
        overflow-y: visible;
      }
      &.form__radiogroup {
        display: flex;
        flex-direction: column;
        min-height: 1rem;
        height: auto;

        .control {
          // margin-top: auto;
        }
      }
        &.form__textarea, &.form__select, &.form__textfield {
        display: flex;
        flex-direction: column;
        min-height: 1rem;
        height: auto;

        .control { 
          // margin-top: auto;
          flex: 0 0 auto;
          min-height: 1rem;
          height: auto;
          display: flex;
          flex-direction: column;
          span, input {
            // margin-top: auto;
          }
        }
      }
    }
    @media (min-width: $desktop) {
      .label {
        min-height: 2em;
      }
    }
  }

  .field.form__checkboxterms, .field.form__checkbox {
    & > p {
      text-align: center;
    }
    p.help {
      position: relative;
      height: auto;
      bottom: 0;
      top: 0;
    }
    p.errormessage {
      color: $danger;
    }

    input.fielderror + .style-checkbox {
      border-color: $danger;
    }
  }

  .field.form__checkbox {
    p.errormessage {
      text-align: left;
    }
  }

  .form__checkboxgroup {
     .control .checkbox.column {
       flex: 1 1 auto;
     }
     .style-checkbox {
       flex: 0 0 auto;
     }
     .checkbox {
       margin-top: 0;
     }
  }

  .field.form__checkbox {
    .checkbox {
      display: flex;
      flex-flow: row wrap;
      .help {
        flex: 0 0 100%;
      }
    }
  }

  input,
  textarea,
  select {
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-style: solid;
    border-width: 2px;
    border-color: $neutral-dk;
  }

  input,
  select {
    &:active,
    &:focus {
      // box-shadow: none;
      cursor: auto;
    }
    // &:-moz-focusring {
    // 	color: transparent;
    // 	text-shadow: 0 0 0 #000;
    // }
  }

  // option {
  // 		&:active, &:focus{
  // 		border: none;
  // 		outline: 0px;
  // 	}
  // }

  p.control {
    margin-bottom: 0;
  }

  .control .select {
    &,
    .module-select {
      width: 100%;
    }
    &::after {
      border-color: $neutral-dk;
    }
    &:hover {
      &::after {
        border-color: lighten($neutral-dk, 20%);
      }
    }
    &:focus-within::after {
      border-color: $secondary;
    }
  }

  .select {
    color: lighten($neutral-dk, 10%);
    height: auto;

    &::before {
      content: "";
      position: absolute;
      display: block;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: $white;
    }

    select {
      position: relative;
      z-index: 10;
      background: transparent;
    }

    select:not([multiple]) {
      padding-right: calc(0.625em - 1px);
    }
  }

  // .datetimepicker
  //   .timepicker
  //   .timepicker-start
  //   .timepicker-hours
  //   .timepicker-input,
  // .datetimepicker
  //   .timepicker
  //   .timepicker-start
  //   .timepicker-minutes
  //   .timepicker-input,
  // .datetimepicker
  //   .timepicker
  //   .timepicker-end
  //   .timepicker-hours
  //   .timepicker-input,
  // .datetimepicker
  //   .timepicker
  //   .timepicker-end
  //   .timepicker-minutes
  //   .timepicker-input,
  // .datetimepicker .timepicker .timepicker-time-divider {
  //   color: $primary-color;
  // }

  .select,
  .select:not(.is-multiple):not(.is-loading) {
    &::after {
      font-family: "pathway-icons";
      content: "\e874";
      display: block;
      position: absolute;
      right: 1.25em;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      border: 0;
    }
  }

  p.help {
    margin: 0;
    display: inline-block;
    @extend %body-2;
  }

  .checkbox {
    position: relative;
    display: inline;
    display: inline-flex;
  }

  input[type="checkbox"] {
    opacity: 0;
    min-height: 0;
    width: 1px;
    height: 1px;
  }

  .style-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: $white;
    border: solid 1px;
    border-color: $black;
    position: relative;
    top: 0;
    left: -4px;
    margin-right: 0.5em;
  }

  .checkbox input[type="checkbox"]:checked + .style-checkbox {
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 7px;
      width: 11px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      top: calc(50% - 2px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .form__checkboxgroup {
    .control {
      margin-top: 20px;
    }
  }

  .control.clone {
    margin-left: auto;

    .icon-cross {
      transform: rotate(45deg);
    }
  }

  .control.delete {
    margin-right: auto;
  }

  .forms-required-message {
    padding: 0 $side-padding;
    text-align: center;
    margin-top: 40px;

    @include tablet {
      margin-top: 60px;
    }
  }

  .form-intro-container {
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 60px;
    }
  }

  ////// Accordion Controls
  @include until($tablet) {
    .form__divider.form-accordion-header {
      cursor: pointer;
      margin-left: -$side-padding;
      margin-right: -$side-padding;
      @include mobileSidePadding;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 10px;
      margin-bottom: 10px;
      background: $primary-color;
      color: $white;
      position: relative;

      h2 {
        margin-bottom: 0;
      }

      &::after {
        content: "\e874";
        position: absolute;
        right: $side-padding;
        font-family: "pathway-icons";
        top: 1.2em;
        transition: transform 0.3s;
      }
      &.accordion-closed {
        &::after {
          transform: rotate(-90deg);
        }
      }

      &:first-of-type {
        margin-top: 60px;
      }
    }

    .toclone.columns {
      margin-bottom: 30px;
    }
  }

  .errormessage,
  .checkbox .errormessage {
    color: $danger;
    padding: 0;
    margin: 0;
  }

  .fielderror {
    border-color: $danger;
  }

  .select,
  .checkbox {
    .errormessage {
      position: absolute;
      top: 40px;
    }
  }

  .checkbox .errormessage {
    width: 100%;
    text-align: left;
  }


	.form__datepicker .control {
		position: relative;
		z-index: 1;

		&::before {
			@include make-pIcon;
			content: "\e922";
			font-size: 1.5em;
			position: absolute;
			left: 2px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;
			pointer-events: none;
		}

		&:hover {
			&::before {
				opacity: .75;
			}
    }
  //iPhone calendar icon fix - if Using Native Mobile
    // @supports (-webkit-touch-callout: none) {
    //   @media (max-width: 420px) {
    //     &::before {
    //       content: none;
    //     }
    //   }
    // }

	}

	.datepicker_input {
		width: 100%;
		font-size: 1rem;
		color: $black;
		padding-left: 3rem;
		background: transparent;
	}

	[data-filepond-item-state*="error"] .filepond--item-panel,
  [data-filepond-item-state*="invalid"] .filepond--item-panel {
    background-color: $danger;
  }

  [data-filepond-item-state="processing-complete"] .filepond--item-panel {
    background-color: $success;
	} 

} //End Module Forms

.mod__flashmessage {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
  border: 2px solid gold;
  background-color: rgba(255, 240, 190, 0.96);
  cursor: pointer;
  z-index: 600;

  p {
    margin-bottom: 0;
  }

  .closebtn {
    font-size: 2.5rem;
    border-radius: 50%;
  }

  .container {
    background: transparent;
  }

  &.hasSuccess,
  &.success {
    border-color: $success;
    background-color: change-color(
      $color: $success,
      $lightness: 90%,
      $alpha: 0.95
    );
  }

  &.hasError,
  &.error {
    border-color: $danger;
    background-color: change-color(
      $color: $danger,
      $lightness: 90%,
      $alpha: 0.95
    );
  }

  @include until($tablet) {
    .level-left {
      padding: 30px;
    }

    p {
      text-align: center;
      font-size: 16px;
    }

    .closebtn {
      position: absolute;
      top: 0px;
      right: 6px;
      padding: 0;
      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 0.5;
    }
  }

}

