.youtube {
	width: 100%;
	padding-top: 56.25%;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto; 
	position: relative;

	iframe {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0; 
		position: absolute;
	}
}
