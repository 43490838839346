.single-testimonial {
  background: $neutral-lt;
  margin-bottom: 40px;
  padding: 74px 34px;

  .single-testimonial-quote {
    position: relative;
    color: $primary;
    text-transform: uppercase;
    text-align: center;
    p {
      font-size: 18px;
      margin-bottom: 60px;
      @include tablet {
        font-size: 35px;
        // padding: 0 60px;
        max-width: 1000px;
        // margin-left: auto;
        // margin-right: auto;
        display: flex;
      }

      &::before {
        font-family: "Poppins";
        content: open-quote;
        display: block;
        font-size: 140px;
        line-height: 1;
        text-align: center;
        margin-bottom: -0.5em;
        @include tablet {
          // position: absolute;
          margin-right: 40px;
        }
      }

      @include tablet {
        &::after {
          font-family: "Poppins";
          content: close-quote;
          display: block;
          font-size: 120px;
          // position: absolute;
          margin-left: 40px;
          margin-top: -0.2em;
        }
      }
    }

    footer {
      font-size: 12px;
      color: $secondary;
      &::before {
        content: "— ";
        margin-right: 0.5em;
        display: inline-block;
      }
      @include tablet {
          font-size: 16px
      }
    }
  }
}
