.services-list {


	.services-accordion {
		display: none;
	}

	.services-accordion-button{
		.p-icon {
			margin-left: .75em;
		}
		&::after {
			content: none;
		}

		&.services-show-more .p-icon {
			transform: rotate( 45deg );
		}
	}

}