// Clinic Colors
$primary-color: "%%Primary%%";
$secondary:"%%Secondary%%";

// Theme Neutrals
$white: #fff;
$black: #000;
$neutral-dk: #5E6B7D;
$neutral-lt: #EFF4F9; 

@import "../../variables/color-functions-base"; // append -base for color replacement

// Bulma Overrides
$text: $neutral-dk;
