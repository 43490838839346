.flash-message {
	padding: 12px;
	background-color: $secondary;
	color: $white;
	text-align: center;

	
	.flash-message-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;  
	}

	.flash-message-content{
		margin: 0 60px;

		p, h2, h3, h4 {
			color: $secondary-invert;
			margin-bottom: 0;
		}
		
		@include until( $tablet ){
			margin: 0 30px;

			p {
				font-size: 12px;
			}
		}
	}

	.flash-message-close{
		@extend %modal-slide-close;
		background-color: transparent;
		border-color: $white;
		top: 50%;
		transform: translateY(-50%);

		@include until( $tablet ){
			width: 16px;
			height: 16px;
			top: 4px;
			transform: none;
			right: 0;
		}

		&::before, &::after {
			width: 18px;
			left: 5px;
			background-color: $white;
			
			@include until( $tablet ){
				width: 12px;
				left: 1px;
			}
		}
		&:hover {
			background-color: $white;
			&::before, &::after {
				background-color: $secondary;
			}
		}
	}
	a {
		@extend %link;
		text-transform: none;
		color: $white;
		text-decoration: underline;
		&:hover {
			color: $white;
			text-shadow: -1px 1px 1px rgba( 0, 0, 0, .15), 
			1px 1px 1px rgba( 0, 0, 0, .15), 
			1px -1px 1px rgba( 0, 0, 0, .15), 
			-1px -1px 1px rgba( 0, 0, 0, .15);
			cursor: pointer;
		}
	}

	a[ href*='tel' ]{
		&:after {
			content: none;
		}
	}

	i.p-icon.icon-cross {
		display: inline-block;
		position: absolute;
		right: 10px;
		color: $text-invert;
		cursor: pointer;
		border-style: solid;
		border-width: 1px;
		border-color: $text-invert;
		height: 20px;
		width: 20px;
		padding: 1px;
		&:hover {
			color: $primary-color;
			border-color: $primary-color;
		}
	}
	
}
