.social-reviews {
	display: block;	
	padding-top: 66px; 
	padding-bottom: 96px;


	@include tablet {
		padding-top: 120px;
		padding-bottom: 100px; 
	}

	@media ( min-width: 1400px ){
		padding-bottom: 180px;
	}

	.social-reviews-title {
		margin-bottom: 1.5em;
	}

	.social-reviews-carousel {
		max-width: 1250px;
		margin-left: auto;
		margin-right: auto;
	}

	.slick-slide {
		width: 400px;
	}


	.social-reviews-item { 
		@media ( min-width: 400px ){
			padding: 0 15px;
		}
	}

	.social-reviews-content {
		background: $white;
		padding: 20px 40px 50px;
	}

	.social-reviews-starbar {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		min-height: 90px;
	}

	.social-reviews-logo {
		width: 106px;
		margin-bottom: 4px;
	}

	.social-reviews-stars {
		color: $warning;

		&.google-stars {
			color: #F4B400;
		}

		&.yelp-stars {
			color: #d32323;
		}

		&.facebook-stars {
			color: #4267B2;
		}
	}

	.social-reviews-byline {
		margin: 2em 0 .5em;
		h3, time {
			display: inline;
		}
	}

	.social-reviews-body {
		margin-bottom: 0;
	}

	.social-reviews-readmore {
		cursor: pointer;
		position: relative;
		z-index: 100;
	}

	.slick-prev {
		right: 100%;
		left: initial;
		margin-right: 30px;
	}

	.slick-next {
		right: initial;
		left: 100%;
		margin-left: 30px;
	}


	@media ( max-width: 1400px ) {

		.social-reviews-carousel {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
		}
		
		.slick-list {
			order: 1;
		}
		.slick-arrow {
			order: 2;
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 30px 20px 40px;
			width: 40px;
			height: 40px;
			transform: none;
			z-index: 1;
			&::after {
				top: 0;
				left: 0;
			}
		}
	
	
		.slick-dots {
			order: 4;
			position: relative;
			margin-top: 0;
			bottom: 0;
		}
	}

}
