.testimonial-grid {
	padding: 80px 20px;
	display: flex;
	flex-direction: column;
	background-color: $primary-color;
	color: $white;
	text-align: center;
	display: block;
	position: relative;

	.testimonial-grid-title {
		display: none;
		@include tablet {
			display: block;
		}
	}

	.testimonial-text {
		@include until( $tablet ){
			max-width: 34em;
			margin-left: auto;
			margin-right: auto;
		}
    }
    
    .testimony-blockquote {

        @include tablet {

            width: 50%;
            margin: 0;
        }
    }

	.testimony-blockquote blockquote {
        padding: 80px 5%;

		@include tablet {
			padding: 80px;
		}

		.testimonial-name {
			margin-top: 20px;
		}

		&::before {
			font-family: 'pathway-icons';
			content: '\e901';
			font-size: 32px;
		}

	}

	.testimonial-title {
		@extend %body-1;
		margin-bottom: 10px;
	}
 
	.testimony-blockquote blockquote::before {
		opacity: .6;
		top: -50px;
		position: relative;
	}

	.testimonial-name {
		@extend %body-2;
		color: $secondary;
		.blockquote-name {
			text-transform: uppercase;
			color: $white;
		}
	}
	
	.blockquote-name {
		// &::after {
		// 	content: ",\2003";
		// }
	}

	.slick-arrow {
		z-index: 999;
	}

	@include tablet {
		.testimonial-carousel::after {
			content: '';
			width: 1px;
			height: 80%;
			position: absolute;
			background: $secondary;
			left: 50%;
			top: 10%;
		}
	}

	//feedback page alt styling
	.feedback & {
		background: $neutral-lt;
		color: $primary-color;

		blockquote::before {
			color: $secondary;
		}

		.blockquote-name {
			color: $primary-color;
		}

		.slick-arrow {
			&::before, &::after {
				color: $primary-color;
			}
		}

		.slick-dots li button::before {
			border-color: $primary-color;

		}
		.slick-dots li.slick-active button::before {
			background: $primary-color;
		}
	}
}
