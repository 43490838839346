.two-col-photo-columns {
	@extend .is-gapless;
}

.half-column-cta {
	@extend %button-primary-invert;
}

.two-col-with-photo {
	min-height: 350px;

	.two-col-photo-container {
		@include maximize-width;
	}

	.two-col-photo-col {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		text-align: center;
		background-color: $primary-color;
		color: $white;

		@include desktop {
			min-height: 550px;
			position: relative;

		}
	}
	
	.two-col-photo-col-image {

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );

			@include desktop {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

	}

	.two-col-photo-col-text {
		display: flex;
		align-items: flex-start;
	}


	.half-column-container {
		padding: 74px 10% 80px;
		width: 100%;
	}


	.half-column-content {
		@extend %body-2;
		margin: 50px 0;
	}

}

.image-right.two-col-with-photo {

	.two-col-photo-col-image {
		order: 2;
	}

}
