// primary shades

// $primary-color-invert: Defaults to $white, user can select $black;
$primary-color-invert: "%%Primary_inv%%";
// $primary-color-light: change-color( scale-color( $primary, $saturation: -30% ), $lightness: 92% );
// $primary-color-light: "%%Primary_cc_070_092%%";
$primary-color-light: "%%Primary_Light%%";
// $primary-color-lighter: scale-color( $primary, $saturation: -35%, $lightness: 35% );
// $primary-color-lighter: "%%Primary_sc_065_135%%";
$primary-color-lighter: "%%Primary_Lighter%%";
// $primary-color-darker: scale-color( $primary, $lightness: -30% );
// $primary-color-darker: "%%Primary_sc_100_070%%";
$primary-color-darker: "%%Primary_Darker%%";


// Secondary shades

// $secondary-invert: Defaults to $white, user can select $black;
$secondary-invert: "%%Secondary_inv%%";
// $secondary-light: change-color( scale-color( $primary, $saturation: -30% ), $lightness: 92% );
// $secondary-light: "%%Secondary_cc_070_092%%";
$secondary-light: "%%Secondary_Light%%";
// $secondary-lighter: scale-color( $secondary, $saturation: -35%, $lightness: 35% );
// $secondary-lighter: "%%Secondary_sc_065_135%%";
$secondary-lighter: "%%Secondary_Lighter%%";
// $secondary-darker: scale-color( $secondary, $lightness: -30% );
// $secondary-darker: "%%Secondary_sc_100_070%%";
$secondary-darker: "%%Secondary_Darker%%";

 
// tertiary shades

// $tertiary-invert: Defaults to $white, user can select $black;
$tertiary-invert: "%%Tertiary_inv%%";
// $tertiary-light: change-color( scale-color( $tertiary, $saturation: -20% ), $lightness: 92% );
// $tertiary-light: "%%Tertiary_cc_080_092%%";
$tertiary-light: "%%Tertiary_Light%%";
// $tertiary-dark: change-color( scale-color( $tertiary, $saturation: -20% ), $lightness: 20% );
// $tertiary-dark: "%%Tertiary_cc_080_020%%";
$tertiary-dark: "%%Tertiary_Dark%%";
 