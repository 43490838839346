.three-col-contact-columns {
	@extend .is-desktop;
}

.three-col-contact {
	text-align: center;
	padding-bottom: 60px;

	@include desktop {
		padding-top: 112px;
		padding-bottom: 120px; 
	}

	.three-col-contact-section-title {
		margin-bottom: 1em;
	}

	.three-col-contact-banner {
		@extend %body-1;
		max-width: 48em;
		margin: 0 auto 4em;
		padding: 0 $side-padding;
	}

	.three-col-contact-group {
		background: $neutral-lt;
		padding: 20px $side-padding;

		@include desktop {
			padding: 54px 20px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

	}

	.three-col-contact-col {

		@include until( $desktop ){
			padding-top: 0;
			padding-bottom: 0;

			&:first-child .three-col-contact-group {
				padding-top: 46px;
			}
	
			&:last-child .three-col-contact-group {
				padding-bottom: 46px;
			}

		}

	}

	.three-col-contact-title {
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: .125em;
		margin-bottom: 24px;
		color: $primary-color;

		@include tablet {
			font-size: 20px;
		}

		@include desktop {
			font-size: 35px;
		}
	}

	.three-col-contact-description {
		line-height: 2;
	}

	.three-col-contact-disclaimer  {
		max-width: 54em;
		padding-left: $side-padding;
		padding-right: $side-padding;
		margin-top: 4em;
		margin-left: auto;
		margin-right: auto;
	}

}
