.app-display-container {
	@extend .is-gapless,
	.is-fullhd;
}

.app-display-col-image {
	@extend .is-6;

	@include tablet {
		order: 2;
	}
}

.app-display-col-text {
	@extend .is-6;
	
	@include tablet {
		order: 1;
	}
}

.app-display {
	padding: 54px 0;
	text-align: center;

	.app-display-image {
		max-height: 287px;
		display: block;
		margin: 0 auto;

		@include tablet {
			max-height: 588px;
		}
		@include desktop {
			max-height: 890px;
		}
	}

	.app-display-content {
		padding: 0 $side-padding;
		@include tablet {
			padding-right: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		@include fullhd {
			padding: 0;
		}
	}

	.app-display-title, .app-display-body {
		margin-bottom: 34px;

		@include tablet {
			margin-bottom: 60px;
		}
	}

	.app-icon {
		display: block;
		height: auto;
		width: 136px;
		margin: 0 auto 18px;

		@include tablet {
		   height: 46px;
		   width: auto;
		   display: inline-block;
		   &:nth-last-of-type(n+2){
			   margin-right: 20px;
		   }
		}
	}

}