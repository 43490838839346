
.slick-dotted.slick-slider {
    margin-bottom: 0;
    padding-bottom: 80px;
    .slick-dots {
        bottom: -10px;
    }
}

.slick-slider .slick-list {
    margin-left: auto;
    margin-right: auto;
}

.slick-list {
    width: 100%;
}

.slick-arrow {
    overflow: visible;
}

.slick-dots {
    left: 0;
}


// button.slick-arrow.slick-prev::before {
//     font-family: 'pathway-icons';
//     content: '\f0d9';
//     padding-right: 3px;
// }
// button.slick-arrow.slick-next::before {
//     font-family: 'pathway-icons';
//     content: '\f0da';
//     padding-left: 2px;
// }
// .slick-prev {
//     left: 20px;
//     z-index: 1;
// }
// .slick-next {
//     right: 20px;
// }
// .slick-dots {
//     margin-top: 30px;
// }

// .slick-slider {
//     .slick-dots {
//         bottom: -80px;

//         li {
//             &:active,
//             :focus,
//             ::-moz-focus-inner {
//                 border: 0;
//             }

//             button {
//                 &::before {
//                     content: "";
//                     box-sizing: content-box;
//                     width: 14px;
//                     height: 14px;
//                     border-radius: 50%;
//                     background-color: transparent;
//                     opacity: 1;
//                     border: solid 1px;
//                     border-color: $secondary-color;
//                 }
//             }

//             &.slick-active {
//                 button::before {
//                     background-color: $secondary-color;
//                     opacity: 1;
//                 }
//             }
//         }

//         &.white-dots {
//             li {
//                 button {
//                 &::before {
//                     border-color: $white;
//                     background: transparent;
//                 }
//             }
//                 &.slick-active button {
//                     &::before {
//                         border-color: $white;
//                         background-color: $white;
//                     }
//                 }
//             }
//         }
//     }
// }

// button.slick-arrow:after {
//     content: '';
//     background-color: $secondary-color;
//     width: 40px;
//     height: 40px;
//     position: absolute;
//     border-radius: 50%;
//     top: -10px;
//     left: -10px;
//     z-index: -1;
// }