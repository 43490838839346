.referring-vets {

	.referring-vets-section-title {
		margin-bottom: 80px;
	}

	.referring-vets-item {
		padding-top: 45px;
	}

	.referring-vets-title {
		text-transform: uppercase;
		color: $secondary;
		margin-bottom: 30px
	}
	
	.referring-vets-link {
		@extend %button-outlined-secondary;
	}
	
	.card-grid-link {
		margin-bottom: 0;
		margin-top: auto;
	}

	.slick-arrow {
		display: none;
	}
	

}
