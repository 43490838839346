.card-grid {

	.slick-dots li button::before {
		border-color: $neutral-dk;
	}

	.slick-dots li.slick-active button::before {
		background: $neutral-dk;
	}

}
