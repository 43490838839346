.generic-cta {
    padding: 4rem 5%;
}

.generic-cta-container {
    text-align: center;

    .generic-cta-title {
        @include until($tablet){
            margin-bottom: 38px;
        }
    }

    a.generic-cta-button {
        @extend %button-outlined-secondary;
    }

   
}

.generic-cta.theme-4-photo {
    background: $neutral-lt;

    .generic-cta-container {
        border: none;
        background: none;
        padding: 0;
        
        .generic-cta-title {
            @include tablet {
                margin-bottom: 28px;
                padding-bottom: 0;
            }
        }

        .content-copy {
            max-width: 42em;
            margin-left: auto;
            margin-right: auto;
        }

        .generic-cta-button {
            @extend %button-outlined-secondary;
        }
    }
}
