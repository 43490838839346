.testimonial-grid-carousel {
	display: flex;

	.testimony-blockquote {
		margin: 0 50px;
		flex: 0 0 auto;
	}

	&.slick-initialized {
		.testimony-blockquote {
			margin: 0;
		}
	}
}