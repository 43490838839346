.three-col-carousel {
	padding: 80px 0 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		text-align: center;
		margin-bottom: 80px;
	}
	
	.affiliate-logo {
		width: 50vw;
		height: auto;		
		margin-left: auto;
		margin-right: auto;

		img {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}

	@include tablet {
		padding: 145px 0 148px;

		.affiliate-logo {
			width: 20vw;
			height: auto;
		}
	}

	.three-col-carousel-description {
		text-align: center;
		@include bodyMax;
		margin-bottom: 2em;
		padding: 0 $side-padding;
	}
}