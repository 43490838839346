.leadership-cards {

    .leadership-text {
        @include tablet {
            width: 224px;
            margin-left: auto;
            margin-right: auto;
    
            h3, h4, .leadership-description {
                padding: 0;
            }
            
            .modal-slide-button {
                padding-left: 0;
            }
        }
        @include desktop {
            width: 250px;
        }
    }

}

.leadership-cards,
.team-cards {
    .group-name {
        font-size: 18px;
        text-align: center;

        @include tablet {
            font-size: 24px;
        }
    }
}