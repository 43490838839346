.module-forms {

	.form-intro-container {
		@include mobileSidePadding;
		padding-top: 0 !important;
		// padding-top: 80px;

		// @include tablet {
		// 	padding-top: 120px;
		// }
	}

	.form-title {
		margin-bottom: .5em;
	}

	.form-intro-container {
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 40px;
		}
	}

	.form-wrapper {
		// padding-top: 40px;
		padding-bottom: 80px;

		@include tablet {
			// padding-top: 80px;
			padding-bottom: 120px;
		}
	}
	

	.column {
		margin-bottom: 10px;

		@include tablet {
			margin-bottom: 28px;
		}
	}

	.form__divider.column {
		margin-top: 40px;
		margin-bottom: 20px;
		color: $secondary;
		h2 {
			@extend %h2;
		}

		@include tablet {
			margin-top: 70px;
			margin-bottom: 40px;
			text-align: center;
		}

		// @include desktop {
		// 	margin-top: 102px;
		// 	margin-bottom: 112px;
		// }

		&:first-child {
			margin-top: 40px;
		}
	}



	input, 
	select,
	.datetimepicker-dummy-wrapper {
		min-height: 46px;
		border-width: 1px;
		border-color: $neutral-dk;
        border-top: none;
        border-left: none;
        border-right: none;
	} 
	
	.textarea, textarea {
		border: solid 1px;
		border-color: $neutral-dk;
	}

    .label {
		@extend %body-1;
	}
	
	.control > .button {
		@extend %button-outlined-primary;
		width: 100%;

		@include tablet {
			width: auto;
			min-width: 200px;
		}
	} 

	// .datepicker-nav .button {
	// 	@extend %button-outlined-primary;
	// 	background: $white;
	// }

	.form__submitbtn .control .button {
		@extend %button-outlined-secondary;

		@include tablet {
			min-width: 320px;
		}
	}

	.form__submitbtn {
		@include tablet {
			margin-top: 40px;

			.control {
				text-align: center;
			}
		}
	}

	.form__checkboxterms {
		margin-top: 40px;
		text-align: center;
		p {
			@extend %body-2;
		}
		p, label {
			color: $neutral-dk;

		}

	}

	.forms-required-message p {
		@extend %body-2;
		color: $neutral-dk;
	}

	.datetimepicker-footer {
		button.button {
			letter-spacing: 0;
			font-size: 14px;
		}
	}

	.style-checkbox::before {
		border-color: $secondary;
	}

	@include until( $tablet ){
		.form__divider.form-accordion-header {
			background: $primary-color;
			color: $white;
		}
	}

	.form__divider.form-accordion-header {
		@include until( $tablet ){
		margin-top: 0;
		margin-bottom: 20px;
		}

	}

	.filepond--panel-root {
		background: $primary-color-light;
		border: solid 1px;
		border-color: $black;
		border-radius: 0;
		opacity: .5;
	}
	
	 .filepond--item-panel{
		background: $primary-color-light;
		border-radius: 0;
	}


}  