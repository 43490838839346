.two-column-promo-columns {
	@extend .is-gapless;
}

.two-column-promo {
	min-height:350px;

	.two-column-promo-container {
		@include maximize-width;
	}



	.two-column-promo-col {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.half-column-title {
		margin-bottom: 1em;
	}

	.half-column-container {
		width: 100%;
		padding: 74px 10% 80px;
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.half-column-cta {
		@extend %button-primary-invert;
		margin: auto auto 0;
	}

	.two-column-promo-col1 {
		background-color: $primary-color;
		.half-column-container {
			color: $white;
		}
		.half-column-cta {
			@extend .is-white;
			// margin-top: 80px;
		}
	}

	.two-column-promo-col2 {
		background-color: $neutral-lt;
		.half-column-cta {
			@extend %button-outlined-secondary;
		}
	}

	.half-column-body,
	.half-column-body-services {
		margin: 0 0 40px;
	}

	.half-column-body {
		@extend %body-1;
		text-align: center;
		width: 100%;
	}

	.half-column-body-services {
		// margin: 0 0 20px;
	}

	h2, h3, h4, p {
		margin-bottom: 1em;
	}

	ul {
		@include tablet {
			columns: 2;
		}
	}

	li {
		margin-bottom: .5em;
	}

	.service-list {
		max-width: 410px;
		margin-left: auto;
		margin-right: auto;
		height: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 50px;
		align-content: center;

		@include tablet {
			flex-direction: row;
			justify-content: space-between;
			align-content: flex-start;
			.service-item {
			flex: 0 0 49%;
			}
		}
	}

	.service-item {
		@extend %body-1;
		text-align: left;
		text-transform: uppercase;
		position: relative;
		padding-left: 30px;
		padding: 5px 0;
		flex: auto;

		.p-icon {
			color: $secondary;
			margin-right: 12px;
			left: 0;
			top: 8px;
		}

	}

	ul .p-icon {
		font-size: 150%;
		vertical-align: middle;
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		left: 0;

	}

	li {
		margin-bottom: 1em;
	}

	li a {
		position: relative;
		display: block;
		padding-left: 35px;
		text-align: left;
	}

}
