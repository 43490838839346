.resource-links {
	background: $neutral-lt;
	text-align: center;
	padding-top: 56px;
	padding-bottom: 90px;
	@media (min-width: $tablet){
	  padding-top: 94px;
	  padding-bottom: 200px;
	}
  
  .resource-links-description {
	display: block;
	max-width: 32.5em;
	margin-left: auto;
	margin-right: auto;
  }

  .resource-links-description {
	margin-bottom: 110px;
  }

}
