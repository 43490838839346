.module-contact.module-forms {
	$form-height: 680px;

	.contact-container {
		position: relative;
		width: 100%;
		max-width: 1600px;
		margin: 0 auto;
		z-index: 1;
	}
	
	.contact-form-header {
		margin-bottom: 60px;
		@include mobileSidePadding;
	}
	
	.columns {
		--columnGap: .75rem !important;
	}

	.form-wrapper {
		@extend %brand-border-top;
		border-top-width: 20px;
		background: $primary-color;
		&, label {
			color: $white;
		}
		::placeholder {
			color: $white;
		}
		@media (min-width:$tablet){
			padding: 30px 45px;
		}
	}

	input, textarea {
		background: $primary-color;
		color: $white;

		border-color: $white;
	}
	
	.contact-image {
		img {
			width: 100%;
			height: auto;
		}
    }
    
    #formContact {
        margin-bottom: 0;
    }

    .form__submitbtn {
				margin-top: 20px;
				.button {
					background: $white;
				}
    }

    .contact-form-message {
        text-align: center;
        @extend %body-2;
    }
	
	@media (min-width: $desktop) {
		.contact-container {
			min-height: $form-height;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}
		.form-wrapper {
			min-height: $form-height;
			// max-width: 630px;
			// position: absolute;
			// right: 0;
			// top: 50%;
			// transform: translateY(-50%);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			order: 2;
			flex: 0 0 630px;
			width: 630px;
		}

		.contact-image {
			width: 75%;
			height: 100%;
			position: relative;
			z-index: -10;
			order: 1;
			margin-right: calc(30% - 630px);
			flex: 0 0 70%;
			img {
				display: block;
			}
		}
		
		.module-field-submit {
			margin-bottom: 0;
		}
	}

}
