@mixin maximize-width {
        width: 100%;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
}

%brand-border-top,
.brand-border-top {
    border-top-style: solid;
    border-top-width: 8px;
    border-top-color: $secondary;
}

$side-padding: 32px;

@mixin mobileSidePadding{
    @include until($desktop){
        padding-left: $side-padding;
        padding-right: $side-padding;
    }
}

$body-max: 56em;

@mixin bodyMax { 
	max-width: $body-max;
	margin-left: auto;
	margin-right: auto; 
}

.visibility-hidden, %visibility-hidden {
	border: 0; 
	clip: rect(0 0 0 0); 
	clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
	-webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
	height: 1px; 
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
  }
  

//Bulma adding arrow to navbar and select
.select:not(.is-multiple):not(.is-loading)::after, 
.navbar-link:not(.is-arrowless)::after {
	border: 0;
	border-radius: 0;
	border-right: 0;
	border-top: 0;
	margin-top: 0;
	content: none;
	display: auto;
	height: auto;
	pointer-events: auto;
	position: auto;
	top: auto;
	transform: auto;
	transform-origin: auto;
	width: auto; 
	display: unset;
	height: unset;
	pointer-events: unset;
	position: unset;
	top: unset;
	transform: unset;
	transform-origin: unset;
	width: unset; 
}

.photo-gallery img.photo-fit-height {
		height: 70vh;
		width: auto !important;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
}

.image-tall img,
img.image-tall {
	height: 100% !important;
	width: auto !important;
}



/*
  For Use with Object Fit Images Polyfill
  node_modules/object-fit-images/dist/ofi.min.js
  
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
	-o-object-fit: $fit;
	   object-fit: $fit;
	@if $position {
		-o-object-position: $position;
		   object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}
  }

  @mixin darkGlow {
	color: $white;
	text-shadow: -1.5px 1.5px 1px rgba( 0, 0, 0, .5), 
	1.5px 1.5px 1px rgba( 0, 0, 0, .5), 
	1.5px -1.5px 1px rgba( 0, 0, 0, .5), 
	-1.5px -1.5px 1px rgba( 0, 0, 0, .5);
	cursor: pointer;
  }