.card-grid {
	padding: 54px 0 70px;

		.card-grid-container {
			@media (min-width: $tablet){
			display: flex;
			flex-direction: row;
			// overflow: hidden;
			justify-content: space-around;
			flex-wrap: wrap;
			&.teams-card-grid-container, 
			&.leadership-card-grid-container {
				justify-content: flex-start;
			}
			}
			@include tablet-only {
			padding-left: $side-padding;
			padding-right: $side-padding;
			}
		}

		.card-grid-section-title {
			text-align: center;
		}

		.card-grid-item {
			padding: 30px 40px;
			width: 80%;
			text-align: center;
			border-style: solid;
			border-width: 1px;
			border-color: $neutral-dk;
			height: 100%;
			display: flex;
			flex-direction: column;
			@include tablet {
				flex: 0 048%;
				margin: 0 auto 40px;
				height: auto;
				justify-content: space-between;
			}
	
			@include desktop {
				flex-basis: 32%;
				margin: 0 0 40px;
			}
	
			@include fullhd {
				flex-basis: 30%;
			}
		}

		.card-grid-offer {
			@include until($tablet){
			font-size: 29px;
			}
		}

		.card-grid-description, .card-grid-link {
			// display: inline-block;
			margin-bottom: 30px;
		}

		.card-grid-offer {
			color: $secondary;
		}

		.slick-track {
			height: 100%;
		}

		.slide {
			height: 100%;
		}

		// Slick UI default handled in assets/scss/utils/slick-overrides.scss
		// .slick-dots li.slick-active button::before {
		//	 opacity: 1;
		//	 background: $neutral-dk;
		// }


		// .slick-dots li button::before {
		//	 color: $neutral-dk;
		//	 border-style: solid;
		//	 border-width: 1px;
		//	 border-color: $neutral-dk;
		//	 border-radius: 20px;
		//	 line-height: 1;
		//	 content: "";
		//	 opacity: 1;
		// }

		.slick-slide {
			margin: 0 9px;
			height: 100%;
			& > div {
			height: 100%;
			width: 100%;
			}
		}

		.slick-list {
			margin: 0 -9px;
		}

		.slick-dotted.slick-slider {
			margin-bottom: 100px;
		}

		.slick-dots {
			bottom: -100px;
		}

		.slick-track{
			display: flex;
			
			.slick-slide{
				display: flex;
				height: auto;
				align-items: center; //optional
				justify-content: center; //optional
			}
		}


}

//Card Grid Modal Slider
.modal-slide {
	position: relative;
}

.modal-slide-close, %modal-slide-close {
	cursor: pointer;
	position: absolute;
	width: 30px;
	height: 30px;
	top: 10px;
	right: 10px;
	background: $white;
	border-style: solid;
	border-width: 1px;
	border-color: $neutral-dk;
		&:hover {
			background: $neutral-dk;
			&::before, &::after {
				background: $white;
			}
		}
		&::before, &::after {
			content: "";
			width: 24px;
			height: 1px;
			background: $neutral-dk;
			display: block;
			position: absolute;
			left: 2px;
			top: 50%;
		}
		&::before {
			transform: rotate(-45deg) translateY(-50%);
		}
		&::after {
			transform: rotate(45deg) translateY(-50%);
		}
}

.lightbox-modal {
	// background: rgba(0,0,0, .8);
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	z-index: 800;
	.lightbox-arrow {
		cursor: pointer;
		flex: 1 0 50%;
		margin-top: 20px;
		text-align: center;
	}
	@include tablet {
		justify-content: space-between;
		align-items: center;
		.lightbox-arrow {
			cursor: pointer;
			flex: 1 1 auto;
			margin-top: 0;
		}
		.modal-content-slider {
			order: 2;
		}
		
		.lightbox-arrow-prev {
			order: 1;
			.p-icon {
				margin-right: auto;
				margin-left: 30px;
			}
		}
		
		.lightbox-arrow-next {
			order: 3;
			.p-icon {
				margin-left: auto;
				margin-right: 30px;
			}
			
		}
	}
}

.lightbox-arrow {
	color: $white;
	font-size: 40px;
	position: relative;
	z-index: 999;

	&:hover {
		color: $secondary;
	}

	@include tablet {
		position: relative;
		display: flex;
		align-items: center;  
		height: 100%;
	}
}

.modal-content-slider {
	overflow-x: hidden;
}

.modal-slide {
	background: $white;
	text-align: center;

}

.modal-background {
	z-index: -10;
}
