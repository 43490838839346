.hero-carousel {
	@include maximize-width;
	@extend .container;

	.hero-carousel-wrap {
		margin-bottom: 0;
		position: relative;
		display: flex;
	}

	.hero-carousel-card {
			position: relative;
			z-index: 10;
			padding: 40px 32px;
			text-align: center;
			min-height: 350px;
			display: flex !important;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;

		@include tablet {
			padding: 80px 100px 120px;
			min-height: 450px;
		}

		@include desktop {
			min-height: 560px;
		}
	}
	
	.hero-carousel-title {
		@extend %hero;
		color: $white;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 0;
		line-height: 1;

		.hero-carousel-subtitle {
			@extend %h1;
			display: block;
			line-height: 1.1;
			margin-top: .25em;
		}

		@include until( $tablet ){
			font-size: 24px;
			// line-height: 1.2;

			.hero-carousel-subtitle {
				font-size: 24px;
				display: inline;
				// line-height: 1.2;
			}
		}
	}

	.hero-carousel-cta {
		@extend %button-white-outline;
		text-align: center;
		margin-top: 40px;
	}

	.hero-carousel-image-wrap {
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			max-width: none;
			object-fit: cover;  
			@include object-fit( cover );
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			background: $primary-color;
			opacity: .9;

			@include tablet {
				opacity: .4;
			}
		}
	}

	.hero-carousel-text {
		width: 100%;
	}

	.slick-track {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: stretch;
		height: 100%;
	}

	.slick-slide {
		display: flex !important;
		height: 100%;
		align-items: stretch;


		> div {
			flex: 1 1 100%;
		}

	}

	.slick-dots {
		background: $primary-color;
		padding: 10px;
		bottom: 0;
		margin-top: 0;
	}

	.slick-arrow {
		width: auto;
		height: auto;
		&::before,
		&::after {
			font-size: 40px;
		}
	}

	@include until($tablet){
		.slick-dots, .slick-arrow {
			display: none!important;
		}
	}

}

.hero-carousel-alt.hero-carousel {

	.slick-dots {
		background: none;
		bottom: 40px;
	}

	.hero-carousel-image-wrap::after {
		opacity: .4;
	}
}