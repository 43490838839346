section.error {
	padding: 80px $side-padding;

	@include tablet {
		padding-top: 105px;
		padding-bottom: 105px;
	}

	@include desktop {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	.error-image {
		width: 80%;
		max-width: 228px;
		margin: 0 auto 30px;

		@include tablet {
			margin-bottom: 60px;
		}
	}

	.error-content,
	.error-body {
		text-align: center;
	}

	.error-title {
		margin-bottom: .3em;
	}

	.error-body {
		@include bodyMax;
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 36px;
		}
	}

	.error-body p {
		font-size: 18px;

		@include tablet {
			font-size: 24px;
		}
	}

}
