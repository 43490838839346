.module-blog-grid {
	padding-top: 50px;
	
	@include tablet {
	  padding-top: 90px;
	}
	
	@include desktop {
	  padding-top: 132px;
	}
	
	  .columns-blog-grid {
		@include tablet-only {
		  padding-left: $side-padding;
		  padding-right: $side-padding;
		}
	
		@include tablet {
		  .column {
			display: flex;
			flex-direction: column;
		  }
		}
	  }
	
	  .blog-grid-card {
		box-shadow: none;
		margin-bottom: 40px;

		@include until( $tablet ){
			width: 100%;
		}
		
		@media (min-width: $tablet){
		  flex: 1 auto;
		  display: flex;
		  flex-direction: column;
		  padding-top: 0;
		  margin-bottom: 130px;
		}
	  }
	
	  .blog-grid-card-image {
		padding-top: 100%;
		background-size: cover;
		background-position: top center;
		&.blog-card-no-hero {
			padding-top: 75%;
		}
		@media (min-width: $tablet){
		  padding-top: 75%;
		  border-top-style: solid;
		  border-top-width: 8px;
		  border-top-color: $secondary;
		}
	  }
	
	  
	
	 .blog-grid-link {
	   margin-bottom: 40px;
	   @media (min-width: $tablet) {
		  margin-bottom: 38px;
	   }
	  }
	
	  .blog-grid-card-content {
		text-align: center;
		padding-top: 40px;
		flex: 1 0 auto;

		h3 {
		  margin-top: 0;
		  font-size: 16px;
		  text-transform: uppercase;
		  font-weight: normal;
		  margin-bottom: 40px;
		}
		@media (min-width: $tablet){
		  padding-top: 33px;
		  border-style: solid;
		  border-width: 1px;
		  border-color: $neutral-dk;
		  border-top: none;
		  text-align: left;
		  display: flex;
		  flex-direction: column;
		  h3 {
			margin-bottom: 50px;
		  }
		}
	  }
	
	  .blog-grid-date {
		color: $secondary;
		font-size: 14px;
		margin-bottom: 14px;
		@media (min-width: $tablet) {
		  margin-bottom: 33px;
		}
	  }
	
	  .blog-grid-link {
		@include tablet {
		  margin-top: auto;
		}
	  }

	  .blog-grid-card-image.blog-card-no-hero {
		position: relative;
		background: $primary-color-light;

			img {
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 50%;
				height: auto;
				max-height: 100%;
				transform: translate( -50%, -50% );
				opacity: .3;
				mix-blend-mode: multiply;
			}
	  }
}