.footer-marketing {
  &.modal {
    z-index: 1000;
  }

  .modal-background {
    opacity: .6;
  }

  .footer-marketing-container {
    @extend %brand-border-top;
    background: $white;
    padding: 70px 30px;
  }

  .three-col-img-image{
    text-align: center;

    
    .p-icon {
      display: inline-block;
      font-size: 70px;
      width: 70px;
      height: 70px;
    }
  }

  a.button {
    text-decoration: none;
  }

}
