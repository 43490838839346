.one-col-info {
	padding: 4rem 5%;
	background: $white;

	&.with-bg {
		background-color: $neutral-lt;

		.one-col-info-content {
			border: none;
		}
	}

	@include desktop {
		padding: 6rem;
	}
}

.one-col-info-content {
	text-align: center;
	border-style: solid;
	border-width: 1px;
	border-color: $primary-color;
	padding: 3rem 2rem;
	background: $white;
	max-width: 1320px;
	margin-left: auto;
	margin-right: auto;

	h2, p {
		padding-bottom: 1.5rem;
		word-break: keep-all;
	}

	.one-col-info-body {
		@include bodyMax;
	}

	.one-col-info-title {
		@include until($tablet){
			margin-bottom: 38px;
		}
	}

	@include desktop {
		padding: 5rem 14rem;
	}
}

.one-col-info-cta {
	@extend %button-outlined-primary;
	margin-top: 2rem;
	margin-right: 2em;

	&:last-of-type{
		margin-right: 0;
	}
}

.photogallerypage .one-col-info {
	background: $neutral-lt;

	.one-col-info-content {
		border: none;
		background: none;
		padding: 0;
		
		.one-col-info-title {
			@include tablet {
				margin-bottom: 28px;
				padding-bottom: 0;
			}
		}

		.one-col-info-body {
			max-width: 42em;
			margin-left: auto;
			margin-right: auto;
		}

		.one-col-info-cta {
			@extend %button-outlined-secondary;
		}
	}
}
