.three-col-carousel {
	padding: 80px 0 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		text-align: center;
		margin-bottom: 80px;
	}

	.three-col-carousel-description {
		margin-top: -3em;
		margin-bottom: 5em;
	}
	
	.affiliate-logo {
		width: 50vw;
		height: auto;		
		margin-left: auto;
		margin-right: auto;
	}
	@include tablet {
		padding: 145px 0 148px;

		.affiliate-logo {
			width: 20vw;
			height: auto;
		}
	}
}