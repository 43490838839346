.module-blog {

  .blog-hero {
	@media (min-width: $tablet){
	  margin-bottom: 62px;
	}
  }

  .blog-hero-image {
	max-height: 70vw;
	overflow: hidden;

	img {
	  display: block;
	  width: auto; 
	  max-width: 100%;
	  height: auto;
	  max-height: auto;
	  margin-left: auto;
	  margin-right: auto;
	}
	@media (min-width: $tablet){
	  margin-bottom: 18px;
	  border-bottom-width: 32px;
	}
  }

  .blog-hero-caption {
	display: none;
	@extend .container;
	width: 100%;
	@include mobileSidePadding;
	@media (min-width: $tablet) {
	  display: block;
	}
  }

  .blog-article {
	@include mobileSidePadding;
	text-align: center;
	@media (min-width: $tablet){
	  text-align: left;
	}
	@media (min-width: $desktop){
	  padding: 0;
	}
	h1 {
	  text-transform: uppercase;
	  font-size: 18px;
	  line-height: 1.3;
	  color: $neutral-dk;
	  @include tablet {
		font-size: 36px;
	  }
	}
	p {
	  margin-bottom: 1em;
	}
  }

  .blog-title {
	  margin-bottom: 16px;
  }

  .blog-author {
	&::after {
	  content: '\00a0';
	  width: 1px;
	  height: 100%;
	  background: $neutral-dk;
	  display: inline-block;
	  margin: 0 .2em;
	  vertical-align: middle;
	}
  }

  .blog-date {
	color: $secondary;
  }

  .blog-social {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
	@media (min-width: $tablet){
	  justify-content: flex-start;
	}
  }

  .blog-social-link {
	margin-left: 1em;
	font-size: 1.5em;
	a {
	  color: $primary;
	  &:hover {
		color: $primary-color-lighter;
	  }
	}
  }

  &.blog-no-hero {
	  .blog-title {
		  margin-bottom: 80px;

		@include tablet {
			margin-bottom: 120px;
		}
	  }
  }

}
