.social-reviews {
	background: $primary-color;
	border-top: solid 8px;
	border-top-color: $secondary;

	.social-reviews-title {
		text-align: center;
		color: $primary-color-invert;
		margin-bottom: 3em;
	}

	.social-reviews-content {
		// background: rgba( 255, 255, 255, .65 );
	}

	.social-reviews-author {
		font-size: 20px;
	}

	.social-reviews-date {
		// @extend %form-text;
	}

	.social-reviews-body {
		@extend %body-2;
	}

	.social-reviews-readmore {
		&::after {
			content: none;
		}
	}

}

// .social-reviews {
// 	text-align: center;
// 	position: relative;
// 	padding: 26px 5%;
// 	background: $primary-color;

// 	@include tablet {
// 		border-top: solid 8px;
// 		border-top-color: $secondary;
// 		padding-top: 98px;
// 		padding-bottom: 98px;
// 	}

// 	.social-reviews-title {
// 		color: $white;
// 		margin-bottom: 1.5em;
// 	}

// 	.social-reviews-container {
// 		background: $white;
// 		padding: 62px 40px 80px;
// 		width: auto;
// 		max-width: 950px;
// 		margin-left: auto;
// 		margin-right: auto;
// 	}

// 	.social-reviews-content {
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 	}

// 	.social-reviews-image {
// 		display: none!important;
// 	}

// 	.social-reviews-title, 
// 	.social-reviews-logo, 
// 	.social-reviews-body {
// 		margin-bottom: 20px;
// 		@include tablet {
// 			margin-bottom: 40px;
// 		}
// 	}
 
// 	.social-reviews-title {
// 		order: 1;
// 	}

// 	.social-reviews-logo {
// 		order: 2;
// 		width: 210px;
// 	}

// 	.social-reviews-body {
// 		margin-top: 40px;
// 		margin-bottom: 40px;
// 		order: 3;
// 	}

// 	.social-reviews-link {
// 		@extend %button-outlined-secondary;
// 		order: 4;
// 		align-self: center;
// 		min-width: 184px;
// 		margin-bottom: 40px;
// 		@include tablet {
// 			min-width: 250px;
// 		}
// 	}

// 	.social-container {
// 		display: flex;
// 		flex-direction: column;
// 	} 

// }

// /////  Reviewtracker Override

// .social-reviews .amplify-widget span,
// .social-reviews .amplify-widget div {
// 	font-family: "Poppins", sans-serif;
// }

// div[ data-test-id="card" ] {
// 	border: none;
// 	border-radius: 0;
// }

// div[ data-test-id="author" ]{
// 	@extend %body-1;
// }

// div[ data-test-id="review-content" ]{
// 	@extend %body-2;
// }



// .amplify-widget {

// 	.fa-chevron-left,
// 	.fa-chevron-right {
// 		color: $white;
// 	}

// 	.sc-hMqMXs {
// 		border: solid 1px;
// 		border-color: $white;
// 		border-radius: 50%;
// 		margin: 0 .5em;

// 		path {
// 			fill: transparent;
// 		}

// 	}

// 	svg[ color="#8D95A3" ]{
// 		background: $white;
// 	}
	
// 	.gbFcHI {
// 		color: $secondary;
// 	}

// }
