.contact-map {

	.contact-map-details-column {
		@extend %brand-border-top;
	}

	.contact-map-details {
		color: $neutral-dk;
		@include tablet {
			max-width: 500px;
		}
		
	}
	
	.contact-map-details-title {
		margin-top: 26px;
		margin-bottom: 6px;
	}

	.contact-map-details-group > div, 
	.contact-map-details-clinic-name {
		position: relative;
		margin-bottom: 22px;
    }

	  .contact-map-details-location-title, 
	  .contact-map-details-hours-title {
		@include until($desktop){
		  font-weight: 700;
		  color: $primary-color;
		}
	  }
	  
	  .contact-map-details-phone-emergency{
		background: $primary-color;
		color: $white;
		padding: .56em 1.56em;
		transition: background .3s;

		@include tablet-only {
			margin-left: -1.56em;
		}
		&:hover { 
		  background: $secondary;
		}
		a, &:hover{
		  color: $white;
		}
	  }
	  .contact-map-details-email {
		h4 {
		  text-transform: none
		}
	  }

	.contact-map-details-title {
		margin-top: 30px;
		margin-bottom: 16px;

		@include desktop {
			margin-top: 46px;
			margin-bottom: 34px;
		}
	}

	.contact-map-details-clinic-name {
		margin-bottom: 20px;

		@include desktop {
			margin-bottom: 34px;
		}
	}

	div.contact-map-details-phone-emergency  {
		display: inline-block;
		text-transform: uppercase;
		margin-bottom: 24px;
		a {
			font-weight: 700;

			@include desktop {
				font-weight: 400;
			}
		}

		@include desktop {
			margin-bottom: 50px;
			width: 100%;
		}

	}

	.contact-map-details-group {
		letter-spacing: .06em;

		@include desktop {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			> div {
				padding-right: 0;
				&.contact-map-details-phone-emergency {
					padding-right: 3em;
				}
			}

			.contact-map-details-location-copy {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	.contact-map-details-phone-general {
		a {
			text-transform: uppercase;
			color: $neutral-dk;
		}
	}

	.contact-map-details-email a{
		color: $neutral-dk;
		text-decoration: underline;
	}

	.contact-map-details-location-title, .contact-map-details-hours-title {
		color: $neutral-dk;
		text-transform: uppercase;
    } 
    
    &.multisite {
			
			.contact-map-details {
        .contact-map-details-phone-general,
        .contact-map-details-email,
        .contact-map-details-location,
        .contact-map-details-hours {
					padding-left: 1.5em;
            &::before {
								left: 0;
            }
        }

    }

        .contact-map-details-group {
            h4 {
                font-weight: bold;
            }
        }

        .contact-map-details-group {
            .contact-map-details-email,
            .contact-map-details-hours,
            h4 {
                margin-bottom: .6em;
            }
        }

        .multisite-location-social-list {
            justify-content: center;
				}
				
				.contact-map-details .contact-map-details-phone-emergency {
					padding: 4px 4px 4px 1.6rem;
					margin-bottom: .6em;
					p {
						margin-bottom: 0;
					}
					&::before {
						top: 4px;
						left: 4px;
					}
				}
    }

}
