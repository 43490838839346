#landingpage {
  header.header {
    display: none;
  }

  .landingpage-header {
   padding-top: 20px;
   padding-bottom: 20px;
   @include mobileSidePadding;
  }
  .forms-required-message {
    margin-top: 0;
    p {
      margin-top: 0;
    }
    p + p {
      margin-top: 1em;
    }
  }

  .form-intro-container {
    text-align: center;
  }

}  