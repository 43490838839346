.one-col-promo {
	padding: 3rem;
	background: $primary-color;
	border-top-style: solid;
	border-top-width: 8px;
	border-top-color: $secondary;
	text-align: center;
	color: $neutral-dk;
	padding: 3rem 5%;

	@include tablet {
		padding: 3rem;
	}

	.one-col-promo-container {
		max-width: 790px;
		margin-left: auto;
		margin-right: auto;
		padding: 4rem 2rem;
		background: $white;

		@include desktop {
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	.one-col-promo-image {
		margin: 0 0 20px 0;

		.p-icon {
			color: $secondary;
			font-size: 40px;
		}
	}

	.three-col-img-subtitle,
	.three-col-img-body {
		margin-bottom: 1rem;
	}

	.one-col-promo-title {
		margin-bottom: 1.25em;
	}

	.one-col-promo-body {
		max-width: 34em;
		margin-left: auto;
		margin-right: auto;
	}

	.one-col-promo-cta {
		@extend %button-outlined-secondary;
		margin-top: 2rem;
	}

}
