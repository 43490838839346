.page-header {
    padding: 0;

    > .container {
        padding-top: 52px;
        padding-bottom: 52px;

        @include tablet {
            padding-top: 114px;
            padding-bottom: 114px;
        }

        @include desktop {
            padding-top: 65px;
            padding-bottom: 65px;
        }
    }

    .page-header-image {
        @include maximize-width;
        max-height: 680px;
        overflow: hidden;
    }

}
