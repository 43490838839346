.slick-prev.slick-arrow::before {
	font-family:'pathway-icons';
	content: '\e875';
}
.slick-next.slick-arrow::before {
	font-family:'pathway-icons';
	content: '\e876';
}
.slick-prev {
	left: 20px;
}
.slick-next {
	right: 20px;
}
.slick-dots {
	margin-top: 30px;
}

.slick-dots li button:before {
	content: '';
	width: 18px; 
	height: 18px;
	border-radius: 50%;
	border: solid 1px;
	border-color: $white;
	opacity: .8;
}

.slick-dots li.slick-active button:before {
	background: $white;
	opacity: 1;
}

.slick-arrow {
z-index: 500;
}

.slick-loading .slick-list {
	background: url( "/img/ajax-loader.gif" );
}
