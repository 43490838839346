//Navbar reset

$navbar-background-color: initial;
$navbar-box-shadow-size: initial;
$navbar-box-shadow-color: initial;


$navbar-item-color: initial;
$navbar-item-hover-color: initial;
$navbar-item-hover-background-color: initial;
$navbar-item-active-color: initial;
$navbar-item-active-background-color: initial;

$navbar-burger-color: $navbar-item-color !default;

$navbar-tab-hover-background-color: initial;
$navbar-tab-hover-border-bottom-color: $secondary !default;
$navbar-tab-active-color: $secondary !default;
$navbar-tab-active-background-color: transparent !default;
$navbar-tab-active-border-bottom-color: $secondary !default;
$navbar-tab-active-border-bottom-style: none !default;
$navbar-tab-active-border-bottom-width: 0px !default;

$navbar-dropdown-background-color: $white !default;
$navbar-dropdown-border-top: none;
$navbar-dropdown-radius: 0px;

$navbar-dropdown-boxed-radius: 0px !default;
$navbar-dropdown-boxed-shadow: none;

$navbar-dropdown-item-hover-color: inherit;
$navbar-dropdown-item-hover-background-color: inherit;
$navbar-dropdown-item-active-color: inherit;
$navbar-dropdown-item-active-background-color: inherit;

$navbar-divider-background-color: inherit;
$navbar-divider-height: 0px;

$navbar-bottom-box-shadow-size: 0 0 0 0 ;
